import styled, { css } from 'styled-components';

export interface InputBaseProps {
  maxHeight?: number;
}

export const Textarea = styled.textarea<InputBaseProps>(
  ({ theme: { utils }, maxHeight }) => css`
    height: ${utils.rem(40)};
    line-height: 1.2;
    max-height: ${maxHeight ? utils.rem(maxHeight) : 'none'};
    resize: none;
  `,
);

import { gql } from '@apollo/client';
import { ChatMessage } from '../../../types';
import { MESSAGE_FRAGMENT } from '../fragments';

export interface ReadMessageResults {
  readMessage: {
    message: ChatMessage;
  };
}

export const READ_MESSAGE = gql`
  mutation readMessage($input: MarkMessageAsReadInput!) {
    readMessage(input: $input) {
      message {
        ...Message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

import { gql } from '@apollo/client';
import { Patient } from '../../../types';
import { PATIENT_FRAGMENT } from '../fragments';

export interface DeletePatientResults {
  deletePatient: {
    patient: Patient;
  };
}

export const DELETE_PATIENT = gql`
  mutation deletePatient($input: DeletePatientInput!) {
    deletePatient(input: $input) {
      patient {
        ...Patient
      }
    }
  }
  ${PATIENT_FRAGMENT}
`;

import { bool, object, string } from 'yup';

interface Options {
  t: (key: string) => string;
}

export const SignInSchema = ({ t }: Options) =>
  object().shape({
    email: string().email().required().label(t('email')),
    password: string().required().label(t('password')),
    rememberMe: bool().label(t(`remember-me`)),
  });

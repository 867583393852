import styled, { css } from 'styled-components';

interface Props {
  isDeleted?: boolean;
}

export const Text = styled.span<Props>(
  ({ isDeleted }) =>
    isDeleted &&
    css`
      font-style: italic;
    `,
);

export const DeletedText = styled.span(
  () => css`
    align-items: center;
    display: inline-flex;
  `,
);

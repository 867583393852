import { Switch, SwitchProps } from '@ui';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = SwitchProps & {
  name: string;
};

export const SwitchField = ({ name, defaultValue, ...props }: Props) => {
  const { control } = useFormContext();
  const {
    field: { onBlur, value, ...field },
    fieldState,
  } = useController({
    control,
    name,
    defaultValue,
  });

  const handleBlur = (event: React.FocusEvent) => {
    event.persist();
    onBlur();
    props.onBlur?.(event);
  };

  return (
    <Switch
      {...props}
      {...field}
      value={value ?? false}
      onBlur={handleBlur}
      error={fieldState.error?.message}
    />
  );
};

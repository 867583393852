import styled, { css } from 'styled-components';

export const Discussion = styled.div(
  () => css`
    flex: 1;
    min-height: 0;
  `,
);

export const ActionsWidget = styled.div(
  ({ theme: { utils } }) => css`
    border-top: 1px solid ${utils.color('secondary', 'main', 0.15)};
  `,
);

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput, FormTextArea } from '@shared';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ContactRequestResults, CONTACT_REQUEST } from '../../api/graphql/mutations';
import { ContactRequestSchema } from './schema';

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
};

export type Locale = {
  email: string;
  firstname: string;
  lastname: string;
  message: string;
  message_sent: string;
  missing_input: string;
  phone: string;
  title: string;
  submit: string;
};

type Props = {
  pharmacyId: string;
  i18n: Locale;
};

export const ContactForm: FC<Props> = ({ pharmacyId, i18n }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(ContactRequestSchema),
  });

  const [isMessageSent, setIsMessageSent] = useState(false);

  const [contactRequest, { loading }] = useMutation<ContactRequestResults>(CONTACT_REQUEST, {
    onCompleted: () => {
      setIsMessageSent(true);
    },
    onError: (errors) => {
      console.error(errors);
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    contactRequest({ variables: { input: { ...data, pharmacyId } } });
  };

  return (
    <div className="c-form-flex-vertical">
      <h1 className="l-page-block-title">{i18n.title}</h1>
      {isMessageSent ? (
        <span className="c-form-message-sent">{i18n.message_sent}</span>
      ) : (
        <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="c-form-input-group">
            <FormInput
              className="c-form-label"
              errors={errors}
              inputName="firstName"
              label={i18n.firstname}
              register={register}
              type="text"
              errorMessage={i18n.missing_input}
              grouped
              isRequired={true}
            />
            <FormInput
              className="c-form-label"
              errors={errors}
              inputName="lastName"
              label={i18n.lastname}
              register={register}
              type="text"
              errorMessage={i18n.missing_input}
              grouped
              isRequired={true}
            />
          </div>
          <FormInput
            type="text"
            errorMessage={i18n.missing_input}
            inputName="email"
            label={i18n.email}
            register={register}
            errors={errors}
            isRequired={true}
          />
          <FormInput
            errors={errors}
            inputName="phoneNumber"
            label={i18n.phone}
            register={register}
            type="text"
            errorMessage={i18n.missing_input}
          />
          <FormTextArea
            inputName="message"
            label={i18n.message}
            register={register}
            errors={errors}
            errorMessage={i18n.missing_input}
            isRequired={true}
          />
          <input className="c-form-cta" type="submit" value={i18n.submit} />
        </form>
      )}
    </div>
  );
};

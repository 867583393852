import styled, { css } from 'styled-components';

export const Container = styled.form(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('paper')};
    border-bottom: 1px solid ${utils.color('secondary', 'main', 0.15)};
    align-items: center;
    display: flex;
    margin: ${utils.rem(0)};
    padding: ${utils.rem(8, 12)};

    > * {
      margin: ${utils.rem(0, 4)};
    }
  `,
);

export const Input = styled.div(
  () => css`
    flex: 1;
  `,
);

export const ButtonLabel = styled.span(
  ({ theme: { utils, breakpoints } }) => css`
    display: none;
    margin-right: ${utils.rem(8)};

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      display: inline;
    }
  `,
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  HTMLProps,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { InputLabel } from '../InputLabel';
import { TextHelper } from '../TextHelper/TextHelper';
import * as Styled from './Input.styled';

export interface InputProps extends HTMLProps<HTMLInputElement> {
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onSubmit?: FormEventHandler;
  input: ReactNode;
  helperText?: ReactNode;
  prependIcon?: ReactNode;
  appendIcon?: ReactNode;
  [key: string]: unknown;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      type = 'text',
      input,
      helperText,
      prependIcon,
      appendIcon,
      onChange,
      onBlur,
      onFocus,
      required,
      ...props
    },
    ref,
  ) => {
    const { value, disabled } = props;
    const [isFocus, setFocus] = useState(false);
    const isFilled = !!value;

    const id = useMemo(() => props.id ?? Math.random().toString().substr(2, 8), []);

    const inputProps = useMemo(
      () => ({
        ref,
        type,
        onChange,
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          e.persist();
          setFocus(false);
          onBlur?.(e);
        },
        onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
          e.persist();
          setFocus(true);
          onFocus?.(e);
        },
        ...props,
      }),
      [onChange, onBlur, onFocus, props, type, ref],
    );

    return (
      <Styled.Base>
        {label && (
          <Styled.Label as={InputLabel as any} filled={isFocus || isFilled} htmlFor={id}>
            {label}
            {required && <Styled.Required>*</Styled.Required>}
          </Styled.Label>
        )}
        <Styled.Control disabled={disabled} focus={isFocus} filled={isFilled} error={!!error}>
          {prependIcon && <Styled.Adornment position="prepend">{prependIcon}</Styled.Adornment>}
          <Styled.Field type={type}>
            <Styled.Wrapper data-replicated-value={value}>
              <Styled.Input as={input as any} id={id} {...inputProps} />
            </Styled.Wrapper>
          </Styled.Field>
          {appendIcon && <Styled.Adornment position="append">{appendIcon}</Styled.Adornment>}
        </Styled.Control>
        {error && (
          <Styled.HelperText as={TextHelper} color="error" icon="error">
            {error}
          </Styled.HelperText>
        )}
        {helperText && <Styled.HelperText as={TextHelper}>{helperText}</Styled.HelperText>}
      </Styled.Base>
    );
  },
);

import { gql } from '@apollo/client';
import { ChatMessage } from '@types';
import { GraphQlClient } from '../../graphqlClient';
import { MESSAGE_FRAGMENT } from '../fragments';

interface ReadMessageSubscriptionResults {
  readMessage: {
    message: ChatMessage;
  };
}

export const READ_MESSAGE_SUBSCRIPTION = gql`
  subscription {
    readMessage {
      message {
        ...Message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export const subscribeToReadMessage = (client: GraphQlClient) => {
  return client
    .subscribe<ReadMessageSubscriptionResults>({
      query: READ_MESSAGE_SUBSCRIPTION,
    })
    .subscribe({
      next: ({ data }) => {
        const { message } = data?.readMessage ?? {};

        if (!message) return;

        client.cache.writeFragment({
          id: message.id,
          fragmentName: 'Message',
          fragment: MESSAGE_FRAGMENT,
          data: {
            status: message.status,
          },
        });
      },
    });
};

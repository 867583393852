import React, { FC, forwardRef, ReactNode } from 'react';
import * as Styled from './Avatar.styled';
import AvatarImage from '@assets/images/avatar.png';

type ImageTypeProps = {
  type: 'image' | 'name';
  value: string;
};

type IconTypeProps = {
  type: 'icon';
  value: ReactNode;
};

type AvatarProps = Styled.AvatarProps & (ImageTypeProps | IconTypeProps);

export const Avatar: FC<AvatarProps> = forwardRef<HTMLDivElement, AvatarProps>(
  ({ color, type = 'name', value, ...props }, ref) => {
    return (
      <Styled.Avatar color={color} ref={ref} {...props}>
        <Styled.AvatarContainer>
          {type === 'name' && value && (
            <span>
              {(value as string)
                .split(' ')
                .map((str: string) => str[0]?.toUpperCase())
                .join('')}
            </span>
          )}
          {type === 'image' && <Styled.AvatarImage src={(value || AvatarImage) as string} />}
          {type === 'icon' && value}
        </Styled.AvatarContainer>
      </Styled.Avatar>
    );
  },
);

import styled, { css } from 'styled-components';

export const Page = styled.main(
  ({ theme: { utils } }) => css`
    align-items: center;
    background-color: ${utils.color('primary', 'main')};
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: ${utils.rem(16)};
  `,
);

export const Form = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    align-items: center;
    background-color: ${utils.background('paper')};
    border-radius: ${borderRadius};
    box-shadow: ${utils.rem(0, 6, 12)} rgba(0, 0, 0, 0.1);
    color: ${utils.color('secondary', 'main')};
    max-width: ${utils.rem(375)};
    padding: ${utils.rem(32)};
    width: 100%;
  `,
);

export const Title = styled.h1(
  ({ theme: { utils } }) => css`
    color: ${utils.color('primary', 'main')};
    font-size: ${utils.rem(22)};
    margin: ${utils.rem(0, 0, 32)};
    text-align: center;
  `,
);

export const FormActions = styled.div(
  ({ theme: { utils } }) => css`
    padding-top: ${utils.rem(16)};
    text-align: center;
  `,
);

export const ErrorMessage = styled.div(
  ({ theme: { utils } }) => css`
    border-left: 4px solid ${utils.color('error', 'main')};
    color: ${utils.color('error', 'main')};
    font-size: ${utils.rem(16)};
    margin: ${utils.rem(0, -32, 16)};
    padding: ${utils.rem(8, 32)};
    text-align: left;
  `,
);

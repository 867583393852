import React, { FC } from 'react';
import * as Styled from './SidebarUserinfo.styled';
import AvatarImage from '@assets/images/avatar.png';

interface Props {
  name: string;
  info?: string;
  avatar?: string | null;
}

export const SidebarUserinfo: FC<Props> = ({ avatar, name, info }) => {
  return (
    <Styled.Container>
      <Styled.Avatar>
        <Styled.Image src={avatar || AvatarImage} alt={name} />
      </Styled.Avatar>
      <Styled.Name>{name}</Styled.Name>
      {info && <Styled.Info>{info}</Styled.Info>}
    </Styled.Container>
  );
};

import { gql } from '@apollo/client';

export interface ForgotPasswordResults {
  forgotPassword: {
    message: string;
  };
}

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
    }
  }
`;

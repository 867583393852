import { HTMLProps } from 'react';
import styled, { css } from 'styled-components';

export interface InputBaseProps {}

interface InputControlProps {
  focus: boolean;
  filled: boolean;
  error?: boolean;
  disabled?: boolean;
}

interface InputFieldProps {
  type?: string;
}

interface InputLabelProps extends HTMLProps<HTMLLabelElement> {
  filled: boolean;
}

interface InputAdornmentProps {
  position: 'prepend' | 'append';
}

export const Base = styled.div<InputBaseProps>(({ theme: { utils } }) => ({
  padding: utils.rem(0, 0),
  position: 'relative',
  textAlign: 'left',
  width: '100%',
  zIndex: 1,

  '&:not(:last-child)': {
    marginBottom: utils.rem(16),
  },
}));

export const Control = styled.div<InputControlProps>(
  ({ theme: { colors, utils, borderRadius }, focus, filled, error, disabled }) => ({
    alignItems: 'flex-start',
    backgroundColor: utils.background('default'),
    border: `1px solid ${colors.palette[error ? 'error' : 'default'].main}`,
    borderRadius,
    color: utils.color('secondary', 'main'),
    display: 'flex',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',

    ...utils.css(filled, {
      color: utils.color('secondary', 'main'),
    }),

    ...utils.css(focus, {
      boxShadow: `0 0 4px 4px ${utils.color(error ? 'error' : 'primary', 'main', 0.5)}`,
    }),

    ...utils.css(disabled, {
      opacity: 0.4,
      cursor: 'not-allowed',
    }),
  }),
);

export const Field = styled.div<InputFieldProps>(({ theme: { utils }, type }) => ({
  position: 'relative',
  width: '100%',

  ...utils.css(type === 'select', {
    '&:after': {
      border: `4px solid ${utils.text('label')}`,
      borderBottom: 'none',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      content: type === 'select' ? '""' : undefined,
      display: 'block',
      marginRight: utils.rem(16),
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  }),
}));

export const Label = styled.label<InputLabelProps>(() => ({}));

export const Adornment = styled.div<InputAdornmentProps>(({ theme: { utils }, position }) => {
  const padding = 12;
  const paddingRight = position === 'prepend' ? 0 : 16;
  const paddingLeft = position === 'append' ? 0 : 16;
  return {
    alignItems: 'center',
    color: utils.color('secondary', 'main'),
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 1,
    padding: utils.rem(padding, paddingRight, padding, paddingLeft),
    zIndex: 1,
  };
});

export const Wrapper = styled.div({
  position: 'relative',
  width: '100%',

  '& textarea': {
    // height: utils.rem(100),
    resize: 'none',
    lineHeight: 1.2,
  },
});

export const Input = styled.div<HTMLProps<HTMLDivElement>>(({ theme: { utils } }) => ({
  backgroundColor: 'transparent',
  borderRadius: 'inherit',
  border: 'none',
  color: 'currentColor',
  display: 'block',
  fontFamily: utils.font('body'),
  fontSize: utils.rem(14),
  lineHeight: 1,
  minHeight: utils.rem(40),
  outline: 'none',
  padding: utils.rem(11, 16), // FIX: should be 12px instead of 11px. Something is wrong with input height...
  width: '100%',
  zIndex: 0,
  WebkitAppearance: 'none',

  '&:disabled': {
    cursor: 'not-allowed',
  },
}));

export const HelperText = styled.div(({ theme: { utils, borderRadius } }) => ({
  padding: utils.rem(4, utils.fromRem(borderRadius) / 2),
}));

export const Required = styled.span(
  ({ theme: { utils } }) => css`
    color: ${utils.color('error', 'main')};
    display: inline-block;
    margin-left: ${utils.em(4)};
  `,
);

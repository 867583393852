import React, { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import * as Styled from './Tooltip.styled';

interface TooltipProps extends Styled.BaseProps {
  tooltipContent: ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  showOn?: 'click' | 'hover';
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipContent,
  placement = 'top',
  showOn = 'hover',
  ...props
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [isVisible, setTooltipVisibile] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 4 } },
      { name: 'offset', options: { offset: [0, 10] } },
    ],
    placement,
  });

  const InEvents = ['focus', showOn === 'click' ? 'click' : 'mouseenter'];
  const OutEvents = ['blur', 'mouseleave'];

  const displayTooltip = () => setTooltipVisibile(true);
  const hideTooltip = () => setTooltipVisibile(false);

  useEffect(() => {
    if (referenceElement) {
      const ref = referenceElement!;
      InEvents.forEach((eventName) => ref.addEventListener(eventName, displayTooltip));
      OutEvents.forEach((eventName) => ref.addEventListener(eventName, hideTooltip));

      return () => {
        InEvents.forEach((eventName) => ref.removeEventListener(eventName, displayTooltip));
        OutEvents.forEach((eventName) => ref.removeEventListener(eventName, hideTooltip));
      };
    }
  }, [referenceElement, showOn]);

  return (
    <>
      {React.Children.map(children, (child: ReactNode) =>
        React.cloneElement(child as JSX.Element, { ref: setReferenceElement }),
      )}
      {isVisible &&
        createPortal(
          <Styled.Base
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            {...props}>
            {tooltipContent}
            <Styled.Arrow placement={placement} ref={setArrowElement} style={styles.arrow} />
          </Styled.Base>,
          document.body,
        )}
    </>
  );
};

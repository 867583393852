import React, { FC, forwardRef, ReactEventHandler } from 'react';
import * as Styled from './Button.styled';

export interface ButtonProps extends Styled.ButtonProps {
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  onClick?: ReactEventHandler<HTMLButtonElement>;
}

export const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, color, size = 'medium', type = 'button', ...props }, ref) => {
    return (
      <Styled.Button color={color} size={size} type={type} {...props} ref={ref}>
        {children}
      </Styled.Button>
    );
  },
);

import { useQuery } from '@apollo/client';
import { CurrentContextResults, CURRENT_CONTEXT } from '@graphql';
import { clearTokens, getAccessToken } from '@shared';
import React, { ComponentType, FC, useCallback, useMemo } from 'react';
import { GraphqlClient } from '../../../api/graphqlClient';
import { AuthContext, AuthContextValues } from '../contexts';

export const WithAuth = <T,>(Component: ComponentType<T>): FC<T> => {
  return (props) => {
    const { data, loading } = useQuery<CurrentContextResults>(CURRENT_CONTEXT, {
      variables: { accessToken: getAccessToken() ?? '' },
      client: GraphqlClient('auth'),
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: false,
    });

    const logout = useCallback(() => {
      clearTokens();
      document.location.assign('/signin');
    }, []);

    const currentUser = data?.currentContext.patient;
    const context = useMemo<AuthContextValues>(
      () => ({
        currentContext: currentUser ?? null,
        fetching: loading,
        isLoggedIn: !loading && !!currentUser,
        logout,
      }),
      [currentUser, loading],
    );

    return (
      <AuthContext.Provider value={context}>
        <Component {...props} />
      </AuthContext.Provider>
    );
  };
};

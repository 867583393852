import styled, { css } from 'styled-components';

export const Page = styled.main(
  ({ theme: { utils } }) => css`
    align-items: center;
    background-color: ${utils.color('primary', 'main')};
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: ${utils.rem(16)};
  `,
);

export const Form = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    align-items: center;
    background-color: ${utils.background('paper')};
    border-radius: ${borderRadius};
    box-shadow: ${utils.rem(0, 6, 12)} rgba(0, 0, 0, 0.1);
    color: ${utils.color('secondary', 'main')};
    max-width: ${utils.rem(768)};
    padding: ${utils.rem(32)};
    width: 100%;
  `,
);

export const Title = styled.h1(
  ({ theme: { utils } }) => css`
    color: ${utils.color('primary', 'main')};
    font-size: ${utils.rem(22)};
    margin: ${utils.rem(0, 0, 32)};
    text-align: center;
  `,
);

export const FormActions = styled.div(
  ({ theme: { utils } }) => css`
    padding-top: ${utils.rem(16)};
    text-align: center;
  `,
);

export const SuccessMessage = styled.div(
  () => css`
    text-align: center;
  `,
);

export const SuccessMessageText = styled.div(
  ({ theme: { utils } }) => css`
    font-weight: 500;
    margin-bottom: ${utils.rem(16)};
  `,
);

export const Link = styled.a(
  ({ theme: { utils } }) => css`
    color: currentColor;
    display: block;
    font-size: ${utils.rem(14)};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `,
);

export const Label = styled.span(
  ({ theme: { utils } }) => css`
    flex: 1;
    font-size: ${utils.rem(14)};
  `,
);

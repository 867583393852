import { gql } from '@apollo/client';
import { Patient } from '../../../types/Patient';
import { PATIENT_FRAGMENT } from '../fragments';

export interface UpdatePatientResults {
  updatePatient: {
    patient: Patient;
  };
}

export const UPDATE_PATIENT = gql`
  mutation updatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      patient {
        ...Patient
      }
    }
  }
  ${PATIENT_FRAGMENT}
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccessRenewalResults, ACCESS_RENEWAL } from '@graphql';
import { getAccessToken, getRefreshToken, storeAccessToken, storeRefreshToken } from '@shared';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { GraphqlClient } from './graphqlClient';

function AuthRequestInterceptor({ headers, ...config }: AxiosRequestConfig) {
  return {
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
}

function AuthResponseInterceptorSuccess(response: AxiosResponse) {
  return response;
}
function AuthResponseInterceptorError(error: any) {
  if (error?.response?.status === 401) {
    const refreshToken = getRefreshToken();
    const { config } = error;

    if (refreshToken) {
      // TODO: Extract in shared function (ETO 2021-12-16)
      return GraphqlClient('auth')
        .mutate<AccessRenewalResults>({
          mutation: ACCESS_RENEWAL,
          variables: {
            input: { refreshToken },
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          if (data?.accessRenewal) {
            const { accessToken, refreshToken } = data.accessRenewal;
            storeAccessToken(accessToken);
            storeRefreshToken(refreshToken);

            return axios(AuthRequestInterceptor(config));
          }
        });
    }
  }

  return Promise.reject(error);
}

export const RestApi = axios.create({
  baseURL: `${process.env.API_ENDPOINT}`,
  headers: {
    Accept: 'application/json',
  },
});

RestApi.interceptors.request.use(AuthRequestInterceptor);
RestApi.interceptors.response.use(AuthResponseInterceptorSuccess, AuthResponseInterceptorError);

import { object, string } from 'yup';

interface Options {
  t: (key: string) => string;
}

export const ForgotPasswordSchema = ({ t }: Options) =>
  object().shape({
    email: string().email().required().label(t('form.fields.email')),
  });

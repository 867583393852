// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import ReactOnRails from 'react-on-rails';
import Turbolinks from 'turbolinks';
import { CURRENT_CONTEXT } from '../src/api/graphql/index.ts';
import { GraphqlClient } from '../src/api/index.ts';
import { ContactFormProvider as ContactForm } from '../src/modules/ContactForm/index.ts';
import { ForgotPasswordPage } from '../src/modules/ForgotPassword/index.ts';
import { LanguageSelector } from '../src/modules/LanguageSelector/index.ts';
import { LoggedInButton } from '../src/modules/LoggedInButton/index.ts';
import { Maps } from '../src/modules/Maps/index.ts';
import { MyAccount } from '../src/modules/MyAccount/index.ts';
import { Order } from '../src/modules/Order/index.ts';
import { PromotionOrderButton } from '../src/modules/PromotionOrderButton/index.ts';
import { ResetPasswordPage } from '../src/modules/ResetPassword/index.ts';
import { getAccessToken, InterceptAppointmentLinks } from '../src/modules/shared/index.ts';
import { SignInPage } from '../src/modules/SignIn/index.ts';
import { SignUpPage } from '../src/modules/Signup/index.ts';

// Import components for rails rendering
ReactOnRails.register({
  ContactForm,
  SignIn: SignInPage,
  SignUp: SignUpPage,
  ForgotPassword: ForgotPasswordPage,
  ResetPassword: ResetPasswordPage,
  Maps,
  LoggedInButton,
  MyAccount,
  Order,
  LanguageSelector,
  PromotionOrderButton,
});

Turbolinks.start();

// Toggles the navbar on mobile navigation
function initializeMenuToggler() {
  document.querySelectorAll('.js-menu-toggler').forEach((element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('.js-main-nav').classList.toggle('active');
    });
  });
}

// Carousel handler
function initializeCarousel() {
  let slideIndex = 0;
  let timeout = false;
  const timeoutDelay = 5000;

  const slides = document.querySelectorAll('.c-image-carousel-slide');
  const arrows = document.querySelectorAll('.c-image-carousel-arrow');

  function switchSlide(nextIndex) {
    slides.forEach((element, i) => {
      element.classList.remove('active');

      if (nextIndex === i) {
        element.classList.add('active');
      }
    });
  }

  function displayNextSlide() {
    switchSlide(slideIndex);

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      slideIndex = (slideIndex + 1) % slides.length;
      displayNextSlide();
    }, timeoutDelay);
  }

  function handleArrowClick(event) {
    event.preventDefault();

    const arrow = event.currentTarget;
    const { direction } = arrow.dataset;

    if (direction === 'previous') {
      slideIndex -= 1;

      if (slideIndex < 0) {
        slideIndex = slides.length - 1;
      }
    } else if (direction === 'next') {
      slideIndex = (slideIndex + 1) % slides.length;
    }

    displayNextSlide();
  }

  function prepareArrows() {
    arrows.forEach((arrow) => {
      arrow.addEventListener('click', handleArrowClick);
    });
  }

  prepareArrows();
  displayNextSlide();
}

function handleRouteHash() {
  const id = document.location.hash.substring(2);
  const section = document.getElementById(id);

  if (!section) {
    return;
  }

  setTimeout(() => {
    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 0);
}

function handleAppointmentLinks() {
  GraphqlClient('auth')
    .query({
      query: CURRENT_CONTEXT,
      variables: { accessToken: getAccessToken() || '' },
      fetchPolicy: 'no-cache',
    })
    .then((response) => {
      const user = response.data.currentContext.patient;
      InterceptAppointmentLinks(user);
    });
}

document.addEventListener('turbolinks:load', () => {
  initializeMenuToggler();
  initializeCarousel();
  handleRouteHash();
  handleAppointmentLinks();
});

import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { utils } }) => css`
    align-items: center;
    background-color: ${utils.color('primary', 'main')};
    color: ${utils.color('primary', 'textContrast')};
    display: flex;
    flex: 1;
    justify-content: center;
  `,
);

export const Sidebar = styled.div(
  ({ theme: { utils, breakpoints } }) => css`
    height: 100%;
    width: ${utils.rem(64)};

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      width: ${utils.rem(240)};
    }

    @media screen and (min-width: ${utils.rem(breakpoints.laptop)}) {
      width: ${utils.rem(300)};
    }
  `,
);

export const Body = styled.div(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('paper')};
    color: ${utils.text('body')};
    flex: 1;
    height: 100%;
  `,
);

import { Button, Icon, Loader, Transition } from '@ui';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import * as Styled from './LightBox.styled';
import { LightBoxContext, LightBoxContextValues, LightBoxFile } from './LightBoxContext';

export const LightBoxProvider: FC = ({ children }) => {
  const [withLightbox, showLightbox] = useState(false);
  const [file, setFile] = useState<Nullable<LightBoxFile>>(null);
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    if (file) {
      window.open(file.url, '_blank');
      showLightbox(false);
    }
  };

  useEffect(() => {
    if (file && file.type === 'image') {
      setLoading(true);
      const img = new Image();
      img.src = file?.url;
      img.onload = () => setLoading(false);
      img.onerror = () => null;
    }
  }, [file]);

  const context = useMemo<LightBoxContextValues>(
    () => ({
      openLightbox: (image: LightBoxFile) => {
        showLightbox(true);
        setFile(image);
      },
      closeLightbox: () => {
        showLightbox(false);
      },
    }),
    [],
  );

  return (
    <LightBoxContext.Provider value={context}>
      {children}
      {createPortal(
        <Transition
          as={Styled.Lightbox}
          visible={withLightbox}
          onTransitionEnd={() => setFile(null)}>
          <Styled.Backdrop onClick={context.closeLightbox} />
          <Styled.CloseButton onClick={context.closeLightbox}>
            <Icon icon="close" size="1x" />
          </Styled.CloseButton>
          {loading && <Loader color="default" />}
          {!loading && file?.type === 'image' && (
            <Styled.Image src={file?.url} alt={file?.caption} />
          )}
          {!loading && file?.type === 'document' && (
            <Styled.File>
              <Icon icon="description" size="5x" />
              <Styled.FileInfo>
                <Styled.FileName>{file.caption}</Styled.FileName>
                <Button color="secondary" size="small" outlined onClick={handleDownload}>
                  Download file
                </Button>
              </Styled.FileInfo>
            </Styled.File>
          )}
        </Transition>,
        document.body,
      )}
    </LightBoxContext.Provider>
  );
};

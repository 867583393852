import React, { PropsWithChildren } from 'react';
import { Item } from './Item';
import { Separator } from './Separator';
import * as Styled from './SidebarNavigation.styled';

type Props = PropsWithChildren<unknown>;

export const SidebarNavigation = ({ children }: Props) => {
  return <Styled.List>{children}</Styled.List>;
};

SidebarNavigation.Item = Item;
SidebarNavigation.Separator = Separator;

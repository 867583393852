import React, { FC } from 'react';
import * as Styled from './Loader.styled';

type LoaderProps = Styled.BaseProps;

export const Loader: FC<LoaderProps> = (props) => {
  return (
    <Styled.Base {...props}>
      <Styled.Span delay={0} />
      <Styled.Span delay={100} />
      <Styled.Span delay={200} />
    </Styled.Base>
  );
};

import styled, { css } from 'styled-components';

export type GridSize = number;

export type GridContainerProps = {
  gutter: number;
};

export type GridCellProps = {
  gutter: number;
  columns: number;
  size?: GridSize;
};

export const GridCell = styled.div<GridCellProps>(
  ({ theme: { utils }, columns, gutter, size }) => css`
    flex: 1;
    padding: 0;

    @media screen and (min-width: 640px) {
      padding: ${utils.rem(gutter / 2)};

      ${!!size &&
      css`
        flex: 0 0 ${(100 / columns) * size!}%;
      `}
    }
  `,
);

export const GridContainer = styled.div<GridContainerProps>(
  ({ theme: { utils }, gutter }) => css`
    padding: 0;

    @media screen and (min-width: 640px) {
      align-items: flex-start;
      display: flex;
      justify-content: stretch;
      margin: ${utils.rem(0, -gutter / 2)};
    }
  `,
);

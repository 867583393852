import { Icon } from '@ui';
import React, { EventHandler, FC, ReactNode } from 'react';
import * as Styled from './Item.styled';

interface Props {
  label: string;
  href: string;
  icon: string;
  actionElement?: ReactNode;
  onClick?: EventHandler<React.MouseEvent>;
}

export const Item: FC<Props> = ({ label, href, icon, actionElement, onClick }) => {
  return (
    <Styled.Item>
      <Styled.Link
        href={href}
        onClick={onClick}
        className={href === location.pathname ? 'active' : ''}>
        <Styled.Icon as={Icon} icon={icon} />
        <Styled.Label>{label}</Styled.Label>
        {actionElement && <Styled.End>{actionElement}</Styled.End>}
      </Styled.Link>
    </Styled.Item>
  );
};

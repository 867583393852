import { useTranslation } from '@i18n';
import { getTypeFromFilename, useLightBox } from '@shared';
import { ChatMessage, ChatMessageAttachment, ChatParticipant } from '@types';
import { Avatar, Chip, Tooltip } from '@ui';
import dayjs from 'dayjs';
import React, { MouseEvent, useEffect } from 'react';
import { FetchSecuredAttachment } from '../../utils';
import { DeletedText } from '../DeletedText';
import { MessageStatus } from '../MessageStatus';
import { MessageAttachment } from './Attachment';
import * as Styled from './Message.styled';

interface Props {
  author: ChatParticipant;
  message: ChatMessage;
  isMe?: boolean;
  hideAvatar?: boolean;
  onRead?: (message: ChatMessage) => void;
}

export const MessageEntry = ({ author, message, isMe, hideAvatar, onRead, ...props }: Props) => {
  const { t } = useTranslation('chat');
  const { openLightbox } = useLightBox();

  const isDeleted = message.status === 'deleted';
  const hasAttachments = !isDeleted && message.attachments.length > 0;

  const handleAttachmentClick =
    (attachment: ChatMessageAttachment) => async (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const base64File = await FetchSecuredAttachment(attachment.originalUrl);

      openLightbox({
        url: base64File,
        caption: attachment.fileName,
        type: getTypeFromFilename(attachment.fileName),
      });
    };

  useEffect(() => {
    if (!isMe && message.status === 'sent') {
      onRead?.(message);
    }
  }, []);

  if (author.role === 'demand_bot' || author.role === 'status_bot') {
    return (
      <Styled.BotMessage>
        <Styled.BotMessageText>
          {t(`message.${author.role}.${message.content}`, {
            date: dayjs(message.createdAt).fromNow(),
          })}
        </Styled.BotMessageText>
      </Styled.BotMessage>
    );
  }

  return (
    <Styled.Message isMe={isMe} isDeleted={isDeleted} {...props}>
      <Styled.Avatar title={author.fullName}>
        {!hideAvatar && (
          <Tooltip
            tooltipContent={author.fullName}
            placement="top"
            color={isMe ? 'secondary' : 'primary'}>
            <Avatar type="image" value={author.avatar?.url || ''} size="medium" />
          </Tooltip>
        )}
      </Styled.Avatar>
      <Styled.Content isMe={isMe}>
        {hasAttachments && (
          <Styled.Attachments>
            {message.attachments.map((attachment) => (
              <MessageAttachment
                key={attachment.id}
                attachment={attachment}
                onClick={handleAttachmentClick(attachment)}
              />
            ))}
          </Styled.Attachments>
        )}
        <Styled.Text>
          <DeletedText isDeleted={isDeleted}>{message.content}</DeletedText>
        </Styled.Text>
        <Styled.Meta>
          {isDeleted && (
            <Styled.MetaDeleted as={Chip} color="error">
              {t('message.status.deleted')}
            </Styled.MetaDeleted>
          )}
          <Styled.MetaText>{dayjs(message.createdAt).fromNow(true)}</Styled.MetaText>
          {!isDeleted && isMe && (
            <Styled.Status>
              <MessageStatus
                status={message.status}
                color={isMe ? 'primary' : 'secondary'}
                invert
              />
            </Styled.Status>
          )}
        </Styled.Meta>
      </Styled.Content>
      {/* {isMe && (
        <Styled.Action>
          <Button icon="only">
            <Icon icon="more_horiz" size="2x" />
          </Button>
        </Styled.Action>
      )} */}
    </Styled.Message>
  );
};

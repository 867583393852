import styled from 'styled-components';

export const Separator = styled.li(
  ({ theme: { utils } }) => `
    list-style: none;
    padding: ${utils.rem(8, 16, 8, 48)};

    &:before {
        background: currentColor;
        content: "";
        display: block;
        height: 1px;
        opacity: .4;
    }
  `,
);

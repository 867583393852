import { getAccessToken } from '@shared';
import React, { FC, useEffect, useState } from 'react';

export const LoggedInButton: FC = () => {
  const [accessToken, setAccessToken] = useState(getAccessToken());

  const isLoggedIn = !!accessToken;

  useEffect(() => {
    const handleStorageEvent = () => {
      setAccessToken(getAccessToken());
    };

    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <a href="/account" className="c-main-nav-login">
          <i className="material-icons c-main-nav-login__icon" role="button">
            account_circle
          </i>
        </a>
      ) : (
        <a href="/signin" className="c-main-nav-login">
          <i className="material-icons c-main-nav-login__icon" role="button">
            account_circle
          </i>
        </a>
      )}
    </>
  );
};

import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Icon } from '../../Icon';

interface Props {
  className?: string;
  errors?: any;
  errorMessage: string;
  grouped?: boolean;
  iconAppend?: string;
  iconPrepend?: string;
  inputClass?: string;
  inputName: string;
  isRequired?: boolean;
  label: string;
  register: (inputName: string) => void;
  type: string;
}

export const FormInput: FC<Props> = ({
  className,
  errors,
  errorMessage,
  grouped,
  iconAppend,
  iconPrepend,
  inputClass,
  inputName,
  isRequired,
  label,
  register,
  type,
}) => {
  const [inputType, setInputType] = useState(type);
  const [passwordIcon, setPasswordIcon] = useState('visibility');
  const handleSwitchType = () => {
    if (inputType === 'text') {
      setInputType('password');
      setPasswordIcon('visibility');
    } else if (inputType === 'password') {
      setInputType('text');
      setPasswordIcon('visibility_off');
    }
  };

  return (
    <label className={clsx('c-form-label ', className && className)}>
      <div className="c-form-label-container">
        <span className="c-form-label-title">
          {label}
          {isRequired && <span className="c-form-label-title-required">*</span>}
        </span>
      </div>
      <div
        className={clsx(
          iconAppend || iconPrepend ? 'c-form-input-icon-append-container' : 'u-grid',
        )}>
        {iconPrepend && (
          <span className="material-icons c-form-input-icon-prepend">{iconPrepend}</span>
        )}
        <input
          className={clsx(
            inputClass ? inputClass : 'c-form-input',
            grouped && 'c-form-input--grouped',
            iconAppend && 'c-login-page-inputs--icon-append',
            iconPrepend && 'c-login-page-inputs--icon-prepend',
          )}
          type={inputType}
          name={inputName}
          {...register(inputName)}
        />
        {iconAppend === 'visibility' ? (
          <span onClick={handleSwitchType} className="material-icons c-form-input-icon-append">
            {passwordIcon}
          </span>
        ) : (
          iconAppend && <Icon icon={iconAppend} />
        )}
      </div>

      {errors[inputName] && (
        <div className="c-form-error">
          <Icon icon="error" className="c-form-error__icon" />
          {errorMessage}
        </div>
      )}
    </label>
  );
};

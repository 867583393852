import styled, { css } from 'styled-components';

export interface StyledListProps {
  withScroll?: boolean;
}

export const List = styled.ul<StyledListProps>(
  ({ withScroll }) => css`
    margin: 0;
    padding: 0;

    ${withScroll &&
    css`
      max-height: 55vh;
      overflow-y: auto;
    `}
  `,
);

import clsx from 'clsx';
import React, { FC } from 'react';

interface Props {
  icon: string;
  className?: string;
}

export const Icon: FC<Props> = ({ icon, className }) => {
  return <span className={clsx('material-icons', className)}>{icon}</span>;
};

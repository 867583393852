import { gql } from '@apollo/client';

export interface BookPromotionResults {
  promotion: any;
}

export const BOOK_PROMOTION = gql`
  mutation bookPromotion($input: BookPromotionInput!) {
    bookPromotion(input: $input) @client {
      promotion {
        id
      }
    }
  }
`;

const ACCESS_TOKEN_KEY = 'auth.accessToken';
const REFRESH_TOKEN_KEY = 'auth.refreshToken';
const REMEMBER_ME_KEY = 'auth.remeberMe';

type Nullable<T> = T | null;

export function storeAccessToken(token: string, rememberMe?: boolean) {
  setStorageItem(ACCESS_TOKEN_KEY, token, rememberMe);
}

export function storeRefreshToken(token: string, rememberMe?: boolean) {
  setStorageItem(REFRESH_TOKEN_KEY, token, rememberMe);
}

export function getAccessToken() {
  return getStorageItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
  return getStorageItem(REFRESH_TOKEN_KEY);
}

export function getRememberMe() {
  return getStorageItem(REMEMBER_ME_KEY) === 'true';
}

export function clearTokens() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
}

function getStorageItem(key: string): Nullable<string> {
  return localStorage.getItem(key) || sessionStorage.getItem(key) || null;
}

function setStorageItem(key: string, token: string, rememberMe?: boolean): void {
  if (rememberMe === undefined) {
    rememberMe = localStorage.getItem(REMEMBER_ME_KEY) === 'true';
  }

  const store = rememberMe ? localStorage : sessionStorage;
  store.setItem(key, token);
  localStorage.setItem(REMEMBER_ME_KEY, `${rememberMe}`);
}

import styled, { css } from 'styled-components';

export const Container = styled.div<{ disabled?: boolean }>(
  ({ theme: { utils }, disabled }) => css`
    display: flex;
    flex-wrap: wrap;
    max-height: 33vh;
    overflow: auto;
    padding: ${utils.rem(8)};

    ${disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
  `,
);

interface FileProps {
  image?: string;
}

export const File = styled.div<FileProps>(
  ({ theme: { utils, borderRadius }, image }) => css`
    background-image: url(${image});
    background-size: cover;
    border-radius: ${borderRadius};
    height: ${utils.rem(64)};
    margin: ${utils.rem(8)};
    position: relative;
    width: ${utils.rem(64)};
  `,
);

export const DeleteFile = styled.div(
  ({ theme: { utils } }) => css`
    align-items: center;
    background-color: ${utils.color('default', 'main')};
    border-radius: 50%;
    cursor: pointer;
    color: ${utils.color('default', 'textContrast')};
    display: flex;
    justify-content: center;
    padding: ${utils.rem(4)};
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);

    &:hover {
      background-color: ${utils.color('error', 'main')};
      color: ${utils.color('error', 'textContrast')};
    }
  `,
);

export const Input = styled.input(
  () => css`
    display: none;
  `,
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useEffect } from 'react';
import { ChatChannel } from '../../context';
import { useChat } from '../../hooks';
import * as Styled from './Channel.styled';

interface Props {
  currentChannel: ChatChannel | null;
}

export const Channel: FC<Props> = memo(({ children, currentChannel = null }) => {
  const { setChannel, channel } = useChat();

  useEffect(() => {
    setChannel(currentChannel);
  }, [currentChannel]);

  return <Styled.Container>{children}</Styled.Container>;
});

export interface Locale {
  locale: string;
  label: string;
}

export const AvailableLocales: Locale[] = [
  {
    locale: 'fr',
    label: 'Français',
  },
  {
    locale: 'nl',
    label: 'Nederlands',
  },
];

import { gql } from '@apollo/client';

export interface ResetPasswordResults {
  resetPassword: {
    message: string;
  };
}

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
    }
  }
`;

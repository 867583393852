import { gql, Reference } from '@apollo/client';
import { ChatMessage } from '../../../types';
import { MESSAGE_FRAGMENT } from '../fragments';

export interface MessagesCacheResults {
  cursor: string;
  hasMore: boolean;
  messages: Reference[];
}
export type MessagesResults = {
  messages: {
    cursor: string;
    hasMore: boolean;
    messages: ChatMessage[];
  };
};

export const MESSAGES = gql`
  query messages($pharmacyId: ID, $limit: Int, $cursor: ID) {
    messages(pharmacyId: $pharmacyId, limit: $limit, cursor: $cursor) {
      cursor
      hasMore
      messages {
        ...Message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isImage?: boolean }>(
  ({ theme: { utils, borderRadius }, isImage }) => css`
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: ${borderRadius};
    flex: 1;
    margin: ${utils.rem(1)};
    max-width: ${utils.rem(200)};
    min-width: ${utils.rem(100)};
    overflow: hidden;
    position: relative;

    ${isImage &&
    css`
      transition: opacity 0.1s;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      &:hover {
        opacity: 0.9;
      }
    `};

    ${!isImage &&
    css`
      transition: all 0.1s;
    `}
  `,
);

export const Link = styled.a(
  ({ theme: { utils } }) => css`
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: ${utils.color('secondary', 'textContrast', 0.1)};
      box-shadow: 0 0 20px -10px ${utils.color('secondary', 'main')};
    }
  `,
);

export const Image = styled.img(
  () => css`
    display: block;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
);

export const File = styled.div(
  ({ theme: { utils } }) => css`
    align-items: center;
    color: ${utils.color('secondary', 'textContrast')};
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: ${utils.rem(16)};
    position: relative;
  `,
);

export const LoadingFile = styled(File)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const FileName = styled.div(
  ({ theme: { utils } }) => css`
    margin-top: ${utils.rem(4)};
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

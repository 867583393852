import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  forwardRef,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { TextHelper } from '../TextHelper/TextHelper';
import * as Styled from './Switch.styled';

export interface SwitchProps extends Styled.SwitchBaseProps {
  name: string;
  value?: boolean;
  defaultValue?: boolean;
  label?: ReactNode;
  error?: string;
  helperText?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  required?: boolean;
}

export const Switch: FC<SwitchProps> = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      name,
      value,
      label,
      defaultValue,
      disabled,
      error,
      helperText,
      onChange,
      onBlur,
      onFocus,
      required,
      ...props
    },
    ref,
  ) => {
    const [isFocus, setFocus] = useState(false);

    const inputProps = useMemo(
      () => ({
        name,
        checked: value,
        defaultChecked: defaultValue,
        disabled,
        onChange,
      }),
      [value, defaultValue, onChange, disabled, name, value],
    );

    const handleBlur = (e: React.FocusEvent) => {
      e.persist();
      setFocus(false);
      onBlur?.(e);
    };
    const handleFocus = (e: React.FocusEvent) => {
      e.persist();
      setFocus(true);
      onFocus?.(e);
    };

    return (
      <Styled.SwitchBase disabled={disabled} {...props}>
        <Styled.SwitchField>
          <Styled.SwitchInput type="checkbox" error={!!error} {...inputProps} ref={ref} />
          <Styled.SwitchBox
            tabIndex={0}
            error={!!error}
            focus={isFocus}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {label && (
            <Styled.SwitchLabel>
              {label}
              {required && <Styled.Required>*</Styled.Required>}
            </Styled.SwitchLabel>
          )}
        </Styled.SwitchField>
        {label && error && (
          <Styled.SwitchHelperText as={TextHelper} color="error" icon="error">
            {error}
          </Styled.SwitchHelperText>
        )}
        {label && helperText && (
          <Styled.SwitchHelperText as={TextHelper}>{helperText}</Styled.SwitchHelperText>
        )}
      </Styled.SwitchBase>
    );
  },
);

import { createContext } from 'react';

export interface LightBoxFile {
  caption?: string;
  url: string;
  type: 'image' | 'document' | null;
}

export interface LightBoxContextValues {
  openLightbox: (image: LightBoxFile) => void;
  closeLightbox: () => void;
}

export const LightBoxContext = createContext<LightBoxContextValues>({
  openLightbox: () => null,
  closeLightbox: () => null,
});

import { ApolloError, NextLink, Observable, Operation } from '@apollo/client';
import {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  // logout,
  storeAccessToken,
  storeRefreshToken,
} from '@shared';
import { GraphqlClient } from '..';
import { AccessRenewalResults, ACCESS_RENEWAL } from './mutations';

export function getValidationErrors({ graphQLErrors }: ApolloError) {
  const [error] = graphQLErrors;
  if (error.message === 'validation_error') {
    const fields = error.extensions!;
    return Object.keys(fields).map((field) => {
      const message: string = fields[field];
      return { field, message: Array.isArray(message) ? message[0] : message };
    });
  }
  return [];
}

export function refreshToken(operation: Operation, forward: NextLink) {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    return new Observable((observer) => {
      GraphqlClient('auth')
        .mutate<AccessRenewalResults>({
          mutation: ACCESS_RENEWAL,
          variables: {
            input: { refreshToken },
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          if (data?.accessRenewal) {
            const { accessToken, refreshToken } = data.accessRenewal;
            storeAccessToken(accessToken);
            storeRefreshToken(refreshToken);

            const freshOperation = SetOperationContext(operation);

            return forward(freshOperation).subscribe(observer);
          }
        })
        .catch(() => {
          observer.error(observer);
          clearTokens();
        });
    });
  } else {
    clearTokens();
  }
}

export function SetOperationContext(operation: Operation): Operation {
  const context = operation.getContext();
  const token = getAccessToken();

  if (operation.operationName === 'currentContext') {
    operation.variables.accessToken = token ?? '';
  }

  operation.setContext({
    headers: {
      ...context.headers,
      authorization: token && `Bearer ${token}`,
    },
  });

  return operation;
}

import styled, { css } from 'styled-components';

export const AuthLinks = styled.div(
  ({ theme: { utils } }) => css`
    line-height: 1.5;
    margin: ${utils.rem(32, 0)};
    text-align: center;
  `,
);

export const AuthLink = styled.a(
  ({ theme: { utils } }) => css`
    color: currentColor;
    display: block;
    font-size: ${utils.rem(14)};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `,
);

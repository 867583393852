import { gql } from '@apollo/client';
import { ChatMessage } from '../../../types';
import { MESSAGE_FRAGMENT } from '../fragments';

export interface CreateMessageResults {
  createMessage: {
    message: ChatMessage;
  };
}

export const CREATE_MESSAGE = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        ...Message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

import React, { FC } from 'react';
import { Icon } from '../../Icon';

interface Props {
  errors?: any;
  errorMessage: string;
  inputName: string;
  isRequired?: boolean;
  label: string;
  register: (inputName: string) => void;
}

export const FormTextArea: FC<Props> = ({
  inputName,
  register,
  label,
  errors,
  errorMessage,
  isRequired,
}) => {
  return (
    <label className="c-form-label">
      <div className="c-form-label-container">
        <span className="c-form-label-title">
          {label}
          {isRequired && <span className="c-form-label-title-required">*</span>}
        </span>
      </div>
      <div className="u-grid">
        <textarea
          className="c-form-input c-form-input--textarea"
          name={inputName}
          {...register(inputName)}
        />
      </div>
      {errors[inputName] && (
        <span className="c-form-error">
          <Icon icon="error" className="c-form-error__icon" />
          {errorMessage}
        </span>
      )}
    </label>
  );
};

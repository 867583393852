import styled, { css } from 'styled-components';

export const Lightbox = styled.div(
  ({ theme: { utils, zIndex } }) => css`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: ${utils.rem(0)};
    position: fixed;
    top: 0;
    transition: opacity 0.2s;
    width: 100vw;
    z-index: ${zIndex.modal};

    &.active {
      opacity: 1;
    }
  `,
);

export const Backdrop = styled.div(
  ({ theme: { utils } }) => css`
    background-color: ${utils.color('secondary', 'main', 0.7)};
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  `,
);

export const Image = styled.img(
  ({ theme: { utils, borderRadius } }) => css`
    border-radius: ${borderRadius};
    box-shadow: ${utils.rem(0, 8, 32)} rgba(0, 0, 0, 0.1);
    display: block;
    max-height: calc(100% - ${utils.rem(32)});
    max-width: calc(100% - ${utils.rem(32)});
    z-index: 1;
  `,
);

export const File = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    align-items: center;
    background-color: ${utils.color('secondary', 'textContrast')};
    border-radius: ${borderRadius};
    box-shadow: ${utils.rem(0, 8, 32)} rgba(0, 0, 0, 0.1);
    color: ${utils.color('secondary', 'main')};
    display: flex;
    justify-content: center;
    max-height: calc(100% - ${utils.rem(32)});
    max-width: calc(100% - ${utils.rem(32)});
    padding: ${utils.rem(32)};
    z-index: 1;
  `,
);

export const FileInfo = styled.div(
  ({ theme: { utils } }) => css`
    display: block;
    padding: ${utils.rem(8)};
  `,
);
export const FileName = styled.div(
  ({ theme: { utils } }) => css`
    font-size: ${utils.rem(18)};
    font-weight: 700;
    margin-bottom: ${utils.rem(8)};
  `,
);

export const CloseButton = styled.button(
  ({ theme: { utils, borderRadius } }) => css`
    background: ${utils.color('secondary', 'main')};
    border: none;
    border-radius: ${borderRadius};
    color: ${utils.color('secondary', 'textContrast')};
    font-size: 1rem;
    line-height: 1;
    padding: ${utils.rem(16)};
    position: fixed;
    right: ${utils.rem(16)};
    top: ${utils.rem(16)};
    z-index: 2;

    &:hover {
      cursor: pointer;
    }
  `,
);

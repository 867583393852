import { Button, Icon } from '@ui';
import React, { ChangeEvent, FC, useRef } from 'react';
import * as Styled from './Attachments.styled';

export interface InitialMessage {
  message: string;
}

interface Props {
  disabled?: boolean;
  onAdd?: (files: File) => void;
}
export const AttachmentButton: FC<Props> = ({ disabled, onAdd }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files) {
      Array.from(files).forEach((file) => onAdd?.(file));
    }

    inputRef.current!.value = '';
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Button icon="only" color="default" disabled={disabled} onClick={handleClick}>
      <Styled.Input
        type="file"
        ref={inputRef}
        onChange={handleAddFile}
        accept="image/*,application/pdf"
        multiple
      />
      {<Icon icon="add_a_photo" />}
    </Button>
  );
};

import { useState } from 'react';

function tryParse(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function useLocalStorage<T>(key: string, defaultValue: T, storage = localStorage) {
  const [storedValue, setStoredValue] = useState(() => {
    const value = storage.getItem(key);
    return value === null ? defaultValue : tryParse(value!);
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  const destroyValue = () => {
    setStoredValue(null);
    storage.removeItem(key);
  };

  return [storedValue, setValue, destroyValue];
}

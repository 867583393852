import React, { ComponentPropsWithoutRef, FC, forwardRef } from 'react';
import * as Styled from './ListItem.styled';

interface ItemProps extends ComponentPropsWithoutRef<'li'> {
  isActive?: boolean;
  isDisabled?: boolean;
}

export const ListItem: FC<ItemProps> = forwardRef<HTMLLIElement, ItemProps>(
  ({ children, ...props }, ref) => {
    return (
      <Styled.Item ref={ref} {...props}>
        {children}
      </Styled.Item>
    );
  },
);

import { I18nContext, I18nContextValues } from '@i18n';
import React, { FC, memo, useEffect, useMemo } from 'react';
import { translate, updateLocale } from './utils';

interface Props {
  i18n: Record<string, any>;
  locale?: string;
}

export const I18nProvider: FC<Props> = memo(({ i18n, locale = 'fr', children }) => {
  const i18nContext = useMemo<I18nContextValues>(() => ({ translations: i18n }), [i18n]);

  useEffect(() => {
    updateLocale(locale, { t: translate(i18nContext.translations, 'shared') });
  }, [I18nContext]);

  return <I18nContext.Provider value={i18nContext}>{children}</I18nContext.Provider>;
});

export const getTypeFromFilename = (fileName: string): 'image' | 'document' | null => {
  const imageTypes = ['jpg', 'jpeg', 'png'];
  const docTypes = ['pdf', 'doc', 'docx', 'txt'];

  const extension = fileName.split('.').pop() ?? '';

  if (imageTypes.includes(extension)) {
    return 'image';
  } else if (docTypes.includes(extension)) {
    return 'document';
  }
  return null;
};

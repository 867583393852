import styled, { css } from 'styled-components';

export const Container = styled.section(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('default')};
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 0;
  `,
);

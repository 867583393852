import { useCallback, useContext } from 'react';
import { I18nContext } from './context';
import { translate } from './utils';

export const useTranslation = (namespace: string) => {
  const context = useContext(I18nContext);

  const t = useCallback(translate(context.translations, namespace), [context, namespace]);

  return { t, ...context };
};

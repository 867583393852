import { createContext } from 'react';
import { ChatMessage, ChatParticipant } from '../../../types';
import { Attachment } from '../components/Attachments';

export type ChatChannel = {
  id: string;
  recipient: ChatParticipant;
};

export interface ChatPolicies {
  canPost: boolean;
}

export interface ChatContextValues {
  attachments: Attachment[];
  actions: {
    readMessage: (message: ChatMessage) => void;
    postMessage: (text: string, attachments?: File[]) => void;
    fetchMoreMessages: () => void;
    addAttachment: (file: File) => void;
    removeAttachment: (index?: number) => void;
  };
  messages: ChatMessage[];
  channel: ChatChannel | null;
  setChannel: (channel: ChatChannel | null) => void;
  user: ChatParticipant | null;
  posting: boolean;
  fetching: boolean;
  policies: ChatPolicies;
  cursor: Nullable<string>;
}

export const ChatContext = createContext<ChatContextValues>({
  attachments: [],
  actions: {
    readMessage: () => null,
    postMessage: () => null,
    fetchMoreMessages: () => null,
    addAttachment: () => null,
    removeAttachment: () => null,
  },
  messages: [],
  channel: null,
  setChannel: () => null,
  user: null,
  posting: false,
  fetching: false,
  policies: {
    canPost: false,
  },
  cursor: null,
});

import { Promotion } from '@types';
import { Button, Dialog } from '@ui';
import React, { useState } from 'react';
import { useTranslation } from '../../i18n/hook';
import { WithProviders, WithTranslation } from '../shared/hoc';
import { PromotionOrderDialog } from './components';

interface Props {
  promotion: Promotion;
  pharmacyId: string;
  i18n: Record<string, object>;
  locale: string;
}

export const PromotionOrderButton = WithProviders(
  WithTranslation(({ promotion, pharmacyId }: Props) => {
    const { t } = useTranslation('promotion');
    const [, hashId] = document.location.hash.split('!/');

    const [withDialog, setDialog] = useState(hashId === promotion.id);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      document.location.hash = `!/${promotion.id}`;
      setDialog(true);
    };

    const handleDialogClose = () => {
      document.location.hash = '!/';
      setDialog(false);
    };

    const handleBooking = () => {
      document.location.assign('/account/order');
    };

    return (
      <>
        <Button color="primary" onClick={handleClick}>
          {t('cta')}
        </Button>

        <Dialog show={withDialog} onClose={handleDialogClose} size="mobile">
          <PromotionOrderDialog
            promotion={promotion}
            pharmacyId={pharmacyId}
            onCancel={handleDialogClose}
            onSubmit={handleBooking}
          />
        </Dialog>
      </>
    );
  }),
  'patient',
);

import { gql } from '@apollo/client';

export interface ContactRequestResults {
  contactRequest: Record<string, unknown>; //{};
}

export const CONTACT_REQUEST = gql`
  mutation contactRequest($input: ContactRequestInput!) {
    contactRequest(input: $input) {
      contactMessage {
        email
        firstName
        lastName
        message
        pharmacyId
        phoneNumber
      }
    }
  }
`;

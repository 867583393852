/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useToggle } from '../../hooks';
import * as Styled from './PopupMenu.styled';

export interface PopupMenuRender {
  closeMenu: () => void;
  change: (payload?: any) => void;
}

interface PopupMenuProps extends Styled.BaseProps {
  hideBackdrop?: boolean;
  anchor?: 'pointer' | 'parent';
  onChange?: (payload?: any) => void;
  renderMenu: (props: PopupMenuRender) => ReactNode;
}

interface Coords {
  left: number;
  top: number;
  width?: number;
}

function makeCoords(pointer: Coords, rect: DOMRect) {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const offset = 10;
  const { height, width } = rect;

  return {
    left: Math.max(offset, Math.min(pointer.left, windowWidth - width - offset)),
    top: Math.max(offset, Math.min(pointer.top, windowHeight - height - offset)),
    width: pointer.width,
  };
}

export const PopupMenu: FC<PopupMenuProps> = ({
  children,
  renderMenu,
  hideBackdrop,
  anchor = 'pointer',
  onChange: onSelect,
  ...props
}) => {
  const baseRef = useRef<HTMLDivElement>(null);
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const [isOpen, toggleMenu, setMenu] = useToggle(false);
  const [rawCoords, setCoords] = useState<Coords>({ left: 0, top: 0 });

  const coords = useMemo(() => {
    return menuRef ? makeCoords(rawCoords, menuRef.getBoundingClientRect()) : rawCoords;
  }, [menuRef, rawCoords]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (anchor === 'pointer') {
      setCoords({ left: event.clientX, top: event.clientY });
    } else if (anchor === 'parent' && baseRef.current) {
      const { left, top, width } = baseRef.current.getBoundingClientRect();
      setCoords({ left, top, width });
    }

    toggleMenu();
  };

  const handleSkipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const closeMenu = () => {
    setMenu(false);
  };

  const changeAndClose = (payload: any) => {
    onSelect?.(payload);
    closeMenu();
  };

  return (
    <Styled.Base ref={baseRef} {...props}>
      {React.Children.map(children, (child: ReactNode) =>
        React.cloneElement(child as JSX.Element, {
          onClick: handleClick,
        }),
      )}
      {isOpen &&
        createPortal(
          <Styled.MenuContainer onClick={closeMenu} hideBackdrop={hideBackdrop}>
            <Styled.Menu
              {...coords}
              ref={(instance) => setMenuRef(instance)}
              onClick={handleSkipClick}>
              {renderMenu({ closeMenu, change: changeAndClose })}
            </Styled.Menu>
          </Styled.MenuContainer>,
          document.body,
        )}
    </Styled.Base>
  );
};

import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  Observable,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getAccessToken, mergeQueryResults } from '@shared';
import ActionCable from 'actioncable';
import { createUploadLink } from 'apollo-upload-client';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { MessagesCacheResults, refreshToken, SetOperationContext } from './graphql';
import { resolvers, typeDefs } from './schema';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        messages: {
          keyArgs: false,
          merge: (
            existing: MessagesCacheResults | undefined,
            incoming: MessagesCacheResults,
            { args, readField },
          ) => {
            const incomingMessages = incoming.messages;
            const existingMessages = existing?.messages.slice(0) ?? [];
            const messages = mergeQueryResults(existingMessages, incomingMessages, {
              readField,
              cursor: args?.cursor,
            });

            return {
              ...existing,
              ...incoming,
              messages,
            };
          },
        },
      },
    },
  },
});

const httpLink = (uri: string) =>
  createUploadLink({
    uri,
    credentials: 'same-origin',
  });

const actionCableLink = new ActionCableLink({
  cable: ActionCable.createConsumer(`${process.env.ACTION_CABLE_ENDPOINT}`),
  channelName: 'GraphqlPatientsChannel',
  connectionParams: () => ({
    accessToken: getAccessToken(),
  }),
});

const splitLink = (graphqlUri: string) =>
  ApolloLink.split(
    function test({ query: { definitions } }) {
      return definitions.some(
        ({ kind, operation }: any) =>
          kind === 'OperationDefinition' && operation === 'subscription',
      );
    },
    actionCableLink,
    httpLink(graphqlUri),
  );

const authLink = new ApolloLink((operation, forward) => {
  return forward(SetOperationContext(operation));
});

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }): void | Observable<any> => {
    if (graphQLErrors) {
      const [error] = graphQLErrors;

      if (error.message === 'unauthorized') {
        return refreshToken(operation, forward);
      }
    }

    if (networkError) {
      const { statusCode } = networkError as ServerError;

      if (statusCode === 401) {
        return refreshToken(operation, forward);
      }
    }
  },
);

function preprareGraphQLClient(url: string) {
  return new ApolloClient({
    link: from([errorLink, authLink, splitLink(url)]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
    },
    typeDefs,
    resolvers,
  });
}

export type GraphQlClient = ApolloClient<object>;

export type GraphqlContext = 'patient' | 'public' | 'auth';
type GraphQLClients = Record<GraphqlContext, GraphQlClient>;

const graphQlClient: GraphQLClients = {
  public: preprareGraphQLClient(`${process.env.GRAPHQL_ENDPOINT}`),
  auth: preprareGraphQLClient(`${process.env.GRAPHQL_ENDPOINT}`),
  patient: preprareGraphQLClient(`${process.env.GRAPHQL_ENDPOINT}/patients`),
};

export const GraphqlClient = (context: GraphqlContext = 'public') => {
  if (!(context in graphQlClient)) {
    throw new Error('Graphql context is not valid');
  }

  return graphQlClient[context];
};

import { LightBoxProvider, usePrevious } from '@shared';
import { ChatMessage } from '@types';
import { Loader } from '@ui';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Components, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useChat } from '../../hooks';
import { MessageEntry } from '../Message';
import * as Styled from './MessageList.styled';

const List = forwardRef<HTMLUListElement>((props, ref) => (
  <Styled.List {...props} ref={ref} />
)) as Components['List'];

const Item = forwardRef<HTMLLIElement>((props, ref) => (
  <Styled.ListItem {...props} ref={ref} />
)) as Components['Item'];

export const MessageList = () => {
  const {
    messages,
    fetching,
    user,
    cursor,
    actions: { fetchMoreMessages, readMessage },
  } = useChat();

  const listRef = useRef<Nullable<VirtuosoHandle>>(null);
  const oldCursor = usePrevious(cursor);
  const oldMessagesCount = usePrevious(messages.length);
  const [isScrolling, setIsScrolling] = useState(false);
  const [firstItemIndex, setFirstItemIndex] = useState(10000);

  const initialTopMostItemIndex = messages.length ? messages.length - 1 : 10000;

  const isCurrentUser = (message: ChatMessage) => {
    return message.author.role === user?.role;
  };

  useEffect(() => {
    const offset = Math.max(0, messages.length - (oldMessagesCount ?? 0) - 1);
    setFirstItemIndex((i) => i - offset);
  }, [messages]);

  return (
    <LightBoxProvider>
      <Virtuoso
        ref={listRef}
        firstItemIndex={firstItemIndex}
        initialTopMostItemIndex={initialTopMostItemIndex}
        data={messages}
        startReached={fetchMoreMessages}
        isScrolling={setIsScrolling}
        alignToBottom
        components={{
          List,
          Item,
          Header: () => {
            return fetching ? (
              <Styled.LoaderContainer>
                <Loader color="secondary" />
              </Styled.LoaderContainer>
            ) : null;
          },
        }}
        followOutput={() => (oldCursor !== cursor ? false : 'smooth')}
        increaseViewportBy={500}
        computeItemKey={(_, message) => message.id}
        itemContent={(_, message: ChatMessage) => {
          const isMe = isCurrentUser(message);

          return (
            <MessageEntry
              key={message.id}
              author={message.author}
              message={message}
              isMe={isMe}
              hideAvatar={isScrolling}
              onRead={readMessage}
            />
          );
        }}
      />
    </LightBoxProvider>
  );
};

import { useApolloClient } from '@apollo/client';
import { UpdatePatientResults, UPDATE_PATIENT } from '@graphql';
import { AvailableLocales } from '@i18n';
import { getAccessToken, List, WithProviders } from '@shared';
import { PopupMenu } from '@ui';
import axios from 'axios';
import React from 'react';

interface Props {
  i18n: Record<string, any>;
  locale: string;
  pharmacyLocales: string[];
}

export const LanguageSelector = WithProviders<Props>(({ locale, pharmacyLocales }) => {
  const client = useApolloClient();

  const handleSelectLanguage = async (selectedLocale: string) => {
    if (selectedLocale === locale) return;

    const accessToken = getAccessToken();

    try {
      await axios.post('/select_locale', { locale: selectedLocale });

      if (accessToken) {
        client.mutate<UpdatePatientResults>({
          mutation: UPDATE_PATIENT,
          variables: {
            input: {
              locale: selectedLocale,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      window.location.assign(window.location.href + '?langChanged=true');
      // When switching language when viewing a single article - its possible that
      // the article is not available in the lang switched to.
      // We now redirect to the article index in this case, but its important to only
      // do this when we are switching lang - not just for any article 404 - otherwise
      // google will think we are daft.
    }
  };

  return (
    <>
      <PopupMenu
        anchor="parent"
        renderMenu={() => {
          return (
            <List>
              {AvailableLocales.map((l) => {
                if (pharmacyLocales.indexOf(l.locale) === -1) return;
                return (
                  <List.Item
                    key={l.locale}
                    isActive={l.locale === locale}
                    onClick={() => handleSelectLanguage(l.locale)}>
                    {l.label}
                  </List.Item>
                );
              })}
            </List>
          );
        }}>
        <span className="lang-selector">
          {locale}
          <i className="material-icons">expand_more</i>
        </span>
      </PopupMenu>
    </>
  );
});

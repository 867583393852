import { Button, ButtonProps, Icon, Loader } from '@ui';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  isSubmitting: boolean;
  isDirty: boolean;
  iconName?: string;
}

export const SubmitButton: FC<Props> = ({
  children,
  isSubmitting,
  isDirty,
  iconName = 'save',
  ...props
}) => {
  return (
    <Button
      type="submit"
      {...props}
      icon="prepend"
      color="primary"
      disabled={!isDirty || isSubmitting}>
      {isSubmitting ? <Loader /> : <Icon icon={iconName} />}
      {children}
    </Button>
  );
};

import { gql } from '@apollo/client';
import { Patient } from '@types';
import { PATIENT_FRAGMENT } from '../fragments';

export interface SignUpResults {
  patientSignUp: {
    patient: Patient;
  };
}

export const SIGNUP = gql`
  mutation patientSignUp($input: PatientSignUpInput!) {
    patientSignUp(input: $input) {
      patient {
        ...Patient
      }
    }
  }
  ${PATIENT_FRAGMENT}
`;

import React, { ComponentType, FC } from 'react';
import { I18nProvider } from '../../../i18n/provider';

interface Props {
  i18n: Record<string, object>;
  locale: string;
}

export const WithTranslation = <T extends Props>(Component: ComponentType<T>): FC<T> => {
  return (props) => {
    return (
      <I18nProvider i18n={props.i18n} locale={props.locale}>
        <Component {...props} />
      </I18nProvider>
    );
  };
};

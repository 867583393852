import { Patient } from '@types';

export const InterceptAppointmentLinks = (user?: Patient) => {
  document.querySelectorAll<HTMLElement>('[data-appointment]').forEach((element) => {
    const refLink = element.dataset.appointment;
    const url = new URL(refLink ?? '');
    const urlParams = new URLSearchParams(url.search);

    if (!refLink || !user) {
      return;
    }

    urlParams.append('firstname', user.firstName);
    urlParams.append('lastname', user.lastName);
    urlParams.append('email', user.email);
    urlParams.append('phone_number', user.phoneNumber);

    element.setAttribute('href', `${url.origin}${url.pathname}?${urlParams.toString()}`);
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Patient } from '@types';
import { createContext } from 'react';

export type AuthContextValues = {
  currentContext: Nullable<Patient>;
  fetching?: boolean;
  isLoggedIn?: boolean;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextValues>({
  currentContext: null,
  fetching: false,
  isLoggedIn: false,
  logout: () => null,
});

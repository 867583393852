/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, Resolvers } from '@apollo/client';

export const typeDefs = gql`
  input BookPromotionInput {
    promotionId: ID!
    quantity: Int!
  }

  type BookPromotionPayload {
    promotion: Promotion!
  }

  extend type Mutation {
    bookPromotion(input: BookPromotionInput!): BookPromotionPayload
  }
`;

export const resolvers: Resolvers = {
  Mutation: {
    bookPromotion: (_, args) => {
      return {
        discussion: {
          id: 'test-123',
        },
        __typename: 'BookPromotionPayload',
      };
    },
  },
};

import styled, { Color } from 'styled-components';

export interface BaseProps {
  color?: Color;
}

interface ArrowProps {
  placement: 'top' | 'right' | 'bottom' | 'left';
}

export const Base = styled.div<BaseProps>(
  ({ theme: { utils, borderRadius, zIndex }, color = 'secondary' }) => ({
    backgroundColor: utils.color(color, 'main'),
    boxShadow: '0 2px 10px -4px rgba(0, 0, 0, .9)',
    color: utils.color(color, 'textContrast'),
    padding: utils.rem(4, 8),
    borderRadius,
    fontSize: utils.rem(13),
    maxWidth: utils.rem(300),
    zIndex: zIndex.tooltip,
  }),
);

export const Arrow = styled.div<ArrowProps>(({ theme: { utils }, placement }) => ({
  background: 'inherit',
  height: utils.rem(8),
  position: 'absolute',
  visibility: 'hidden',
  width: utils.rem(8),

  '&::before': {
    background: 'inherit',
    content: '""',
    height: utils.rem(8),
    position: 'absolute',
    transform: 'rotate(45deg)',
    visibility: 'visible',
    width: utils.rem(8),
  },

  ...utils.css(placement === 'top', {
    bottom: utils.rem(-4),
  }),
  ...utils.css(placement === 'bottom', {
    top: utils.rem(-4),
  }),
  ...utils.css(placement === 'left', {
    right: utils.rem(-4),
  }),
  ...utils.css(placement === 'right', {
    left: utils.rem(-4),
  }),
}));

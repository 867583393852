import styled, { css, Device } from 'styled-components';

export interface BaseProps {
  size?: Device;
}

export const Base = styled.section<BaseProps>(
  ({ theme: { utils, zIndex, breakpoints }, size }) => css`
    align-items: flex-end;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: ${utils.rem(16)};
    position: fixed;
    opacity: 0;
    top: 0;
    transition: opacity 0.2s;
    width: 100vw;
    z-index: ${zIndex.modal};

    ${utils.breakpoint('tablet') &&
    css`
      align-items: center;
    `}

    ${size &&
    utils.breakpoint(size) &&
    css`
      ${ContentBox} {
        max-width: ${utils.rem(breakpoints[size as keyof typeof breakpoints])};
      }
    `}

    &.active {
      opacity: 1;

      ${ContentBox} {
        transform: translateY(0%);
      }
    }
  `,
);

export const Backdrop = styled.div(
  ({ theme: { utils } }) => css`
    background-color: ${utils.color('secondary', 'main', 0.7)};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  `,
);

export const ContentBox = styled.div(
  ({ theme: { utils } }) => css`
    background: ${utils.background('paper')};
    box-shadow: ${utils.rem(0, 8, 32)} rgba(0, 0, 0, 0.1);
    border-radius: ${utils.rem(16, 16, 0, 0)};
    max-height: 100%;
    overflow: auto;
    margin: 0;
    min-height: utils.rem(100);
    padding: 0;
    position: relative;
    transition: transform 0.3s;
    transform: translateY(50%);
    width: 100%;
    z-index: 1;

    ${utils.breakpoint('tablet') &&
    css`
      border-radius: ${utils.rem(16)};
      max-height: calc(100vh - ${utils.rem(32)});
      max-width: calc(100vw - ${utils.rem(32)});
    `}
  `,
);

export const CloseButton = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    background-color: ${utils.background('paper')};
    border-radius: 0 ${borderRadius} 0;
    color: ${utils.color('secondary', 'main', 0.7)};
    cursor: pointer;
    padding: ${utils.rem(16)};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    &:hover {
      color: ${utils.color('secondary', 'main')};
    }
  `,
);

export const Title = styled.h1(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('paper')};
    color: ${utils.color('secondary', 'main')};
    font-size: ${utils.rem(18)};
    font-weight: 600;
    line-height: ${utils.rem(32)};
    margin: ${utils.rem(-16, 0, 0)};
    padding: ${utils.rem(10)};
    position: sticky;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    z-index: 1;
  `,
);

export const Actions = styled.div<{ align?: 'flex-start' | 'center' | 'flex-end' }>(
  ({ theme: { utils }, align = 'flex-start' }) => css`
    align-items: center;
    background-color: ${utils.background('paper')};
    bottom: 0;
    border-top: 1px solid ${utils.color('default', 'main')};
    display: flex;
    justify-content: ${align};
    padding: ${utils.rem(16)};
    position: sticky;
    z-index: 1;

    > * {
      margin-right: ${utils.rem(8)};
    }
  `,
);

export const Body = styled.div(
  ({ theme: { utils } }) => css`
    overflow: hidden;
    padding: ${utils.rem(32)};
    position: relative;
    z-index: 0;
  `,
);

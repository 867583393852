import { useApolloClient } from '@apollo/client';
import {
  CREATE_MESSAGE,
  MESSAGES,
  MessagesResults,
  NewMessageSubscriptionResults,
  NEW_MESSAGE_SUBSCRIPTION,
  READ_MESSAGE,
  subscribeToReadMessage,
} from '@graphql';
import { useTranslation } from '@i18n';
import { useAuth, WithProviders } from '@shared';
import '@shared/utils/date';
import { ChatParticipant, Pharmacy } from '@types';
import React, { FC, useEffect } from 'react';
import { ChatChannel } from '../../context';
import { Channel } from '../Channel';
import { Chat, ChatDefinitions } from '../Chat';
import { MessageInput } from '../MessageInput';
import { MessageList } from '../MessageList';
import * as Styled from './Discussion.styled';

interface Props {
  pharmacy: Pharmacy;
}

const CHAT_DEFINITIONS: ChatDefinitions = {
  readMessage: { document: READ_MESSAGE },
  postMessage: { document: CREATE_MESSAGE },
  messages: {
    document: MESSAGES,
    resolver: (data: MessagesResults) => data?.messages,
  },
  subscribeNewMessages: {
    document: NEW_MESSAGE_SUBSCRIPTION,
    resolver: (data: NewMessageSubscriptionResults) => data.newMessage,
  },
};

export const Discussion: FC<Props> = WithProviders<Props>(({ pharmacy }) => {
  const { t } = useTranslation('chat');
  const { currentContext } = useAuth();
  const client = useApolloClient();

  const currentUser: ChatParticipant = {
    id: currentContext!.id,
    avatar: currentContext!.avatar,
    fullName: currentContext!.fullName,
    role: 'patient',
  };

  const channel: ChatChannel = {
    id: pharmacy?.id || '',
    recipient: {
      id: pharmacy.id,
      avatar: pharmacy.logo,
      fullName: pharmacy.name,
      role: 'pharmacy',
    },
  };

  useEffect(() => {
    const observer = subscribeToReadMessage(client);
    return () => observer.unsubscribe();
  }, []);

  return (
    <Chat user={currentUser} definitions={CHAT_DEFINITIONS}>
      <Channel currentChannel={channel}>
        <Styled.Discussion>
          <MessageList />
        </Styled.Discussion>
        <Styled.ActionsWidget>
          <MessageInput />
        </Styled.ActionsWidget>
      </Channel>
    </Chat>
  );
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from '@i18n';
import { Icon, InputLabel, TextHelper } from '@ui';
import React, {
  FocusEvent,
  FocusEventHandler,
  HTMLProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  StylesConfig,
  ThemeConfig,
} from 'react-select';
import { useTheme } from 'styled-components';
import * as Styled from './SelectField.styled';

interface Option {
  value: string;
  label: ReactNode;
}
type Props = HTMLProps<HTMLSelectElement> & {
  name: string;
  label?: ReactNode;
  options: any;
  onChange?: (value: unknown) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  helperText?: ReactNode;
  prependIcon?: ReactNode;
  appendIcon?: ReactNode;
};

export const SelectField = ({
  name,
  label,
  options,
  helperText,
  prependIcon,
  appendIcon,
  disabled,
  defaultValue,
  multiple,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  required,
  ...props
}: Props) => {
  const id = useMemo(() => props.id ?? Math.random().toString().substr(2, 8), []);

  const { t } = useTranslation('shared');
  const theme = useTheme();
  const { control, setValue, watch } = useFormContext();
  const {
    field: { value, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
  });

  const [isFocus, setFocus] = useState(false);
  const isFilled = !!watch(name);

  const selectTheme: ThemeConfig = useMemo(
    () => ({
      borderRadius: theme.borderRadius as number,
      colors: {
        primary: theme.utils.color('primary', 'main'),
        primary25: theme.utils.color('primary', 'main', 0.25),
        primary50: theme.utils.color('primary', 'main', 0.5),
        primary75: theme.utils.color('primary', 'main', 0.75),
        danger: theme.utils.color('error', 'main'),
        dangerLight: theme.utils.color('error', 'main', 0.25),
        neutral0: theme.utils.color('secondary', 'main', 0.01),
        neutral5: theme.utils.color('secondary', 'main', 0.05),
        neutral10: theme.utils.color('secondary', 'main', 0.1),
        neutral20: theme.utils.color('secondary', 'main', 0.2),
        neutral30: theme.utils.color('secondary', 'main', 0.3),
        neutral40: theme.utils.color('secondary', 'main', 0.4),
        neutral50: theme.utils.color('secondary', 'main', 0.5),
        neutral60: theme.utils.color('secondary', 'main', 0.6),
        neutral70: theme.utils.color('secondary', 'main', 0.7),
        neutral80: theme.utils.color('secondary', 'main', 0.8),
        neutral90: theme.utils.color('secondary', 'main', 0.9),
      },
      spacing: {
        baseUnit: 4,
        controlHeight: 40,
        menuGutter: 4,
      },
    }),
    [theme],
  );

  const selectStyles: StylesConfig = useMemo(
    () => ({
      control: (provided, { isFocused }) => ({
        ...provided,
        borderWidth: 0,
        boxShadow: isFocused
          ? `0 0 4px 4px ${theme.utils.color('primary', 'main', 0.5)}`
          : undefined,
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 0,
        zIndex: 99,
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0,
      }),
      valueContainer: (provided) => ({
        ...provided,
        fontSize: theme.utils.rem(14),
        padding: theme.utils.rem(0, 16),
      }),
      option: (provided, { isSelected }) => ({
        ...provided,
        // background: 'none',
        color: isSelected ? theme.utils.color('primary', 'textContrast') : 'currentColor',
        fontSize: theme.utils.rem(14),
        fontWeight: 500,
        padding: theme.utils.rem(8, 16),
      }),
      singleValue: (provided) => ({
        ...provided,
        color: theme.utils.color('secondary', 'main'),
        fontWeight: 300,
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: theme.utils.color('secondary', 'main'),
        borderRadius: theme.borderRadius,
        color: theme.utils.color('secondary', 'textContrast'),
        lineHeight: 1,
        overflow: 'hidden',
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: 'currentColor',
        fontWeight: 500,
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }),
      noOptionsMessage: (provided) => ({
        ...provided,
        color: theme.utils.color('secondary', 'main', 0.7),
        fontSize: theme.utils.rem(14),
      }),
    }),
    [theme],
  );

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);
    setFocus(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
    setFocus(false);
  };

  const handleChange = (data: Option | Option[]) => {
    let freshValue;
    if (Array.isArray(data)) {
      freshValue = data.map((option) => option.value);
    } else {
      freshValue = data.value;
    }

    field.onChange();
    onChange?.(freshValue);
    setValue(name, freshValue);
  };

  const getValue = useCallback(() => {
    if (!value) {
      return;
    }
    if (multiple) {
      return options.filter((option: Option) => value.includes(option.value));
    }
    return options.find((option: Option) => value.includes(option.value));
  }, [options, value]);

  return (
    <Styled.Base>
      {label && (
        <Styled.Label as={InputLabel as any} filled={isFocus || isFilled} htmlFor={id}>
          {label}
          {required && <Styled.Required>*</Styled.Required>}
        </Styled.Label>
      )}
      <Styled.Control disabled={disabled} focus={isFocus} filled={isFilled} error={!!error}>
        {prependIcon && <Styled.Adornment position="prepend">{prependIcon}</Styled.Adornment>}
        <Styled.Field>
          <Select
            {...field}
            id={id}
            options={options}
            isMulti={multiple}
            value={getValue()}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            theme={selectTheme}
            styles={selectStyles}
            placeholder={placeholder ?? t('fields.select.placeholder')}
            noOptionsMessage={() => t('fields.select.noOptions')}
            loadingMessage={t('fiedls.select.loading')}
            components={{
              ClearIndicator,
              DropdownIndicator,
              IndicatorSeparator: null,
            }}
          />
        </Styled.Field>
        {appendIcon && <Styled.Adornment position="append">{appendIcon}</Styled.Adornment>}
      </Styled.Control>
      {error && (
        <Styled.HelperText as={TextHelper} color="error" icon="error">
          {error.message}
        </Styled.HelperText>
      )}
      {helperText && <Styled.HelperText as={TextHelper}>{helperText}</Styled.HelperText>}
    </Styled.Base>
  );
};

const ClearIndicator = ({ isFocused, clearValue }: ClearIndicatorProps) => (
  <Styled.ClearIndicator isFocused={isFocused} onClick={clearValue}>
    <Icon icon="cancel" />
  </Styled.ClearIndicator>
);
const DropdownIndicator = ({ isFocused }: DropdownIndicatorProps) => (
  <Styled.DropdownIndicator isFocused={isFocused}>
    <Icon icon="arrow_drop_down" size="1x" />
  </Styled.DropdownIndicator>
);

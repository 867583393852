import styled, { css } from 'styled-components';

export type TitleLevel = '1' | '2' | '3' | '4';

interface TitleProps {
  level?: TitleLevel;
}

export const Title = styled.div<TitleProps>(
  ({ theme: { utils }, level }) => css`
    color: ${utils.color('secondary', 'main')};
    margin: 0;

    ${level === '1' &&
    css`
      font-size: ${utils.rem(28)};
      font-weight: 700;
      line-height: 1;
    `}

    ${level === '2' &&
    css`
      font-size: ${utils.rem(18)};
      font-weight: 700;
      line-height: 2;
      margin-top: ${utils.rem(24)};
      margin-bottom: ${utils.rem(4)};
      text-transform: uppercase;
    `}
      
      ${level === '3' &&
    css`
      font-size: ${utils.rem(16)};
      font-weight: 700;
      line-height: 1.5;
      margin-top: ${utils.rem(24)};
      margin-bottom: ${utils.rem(4)};
      text-transform: uppercase;
    `}

      ${level === '4' &&
    css`
      font-size: ${utils.rem(16)};
      font-weight: 700;
      line-height: 1.2;
      margin-top: ${utils.rem(20)};
      margin-bottom: ${utils.rem(4)};
    `}

    &:first-child {
      margin-top: 0;
    }
  `,
);

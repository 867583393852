import { RestrictedPage, WithProviders } from '@shared';
import '@shared/utils/date';
import { Pharmacy } from '@types';
import React from 'react';
import { I18nProvider } from '../../i18n/provider';
import { Discussion } from './components';

interface Props {
  pharmacy: Pharmacy;
  i18n: Record<string, any>;
  locale: string;
}

export const Order = WithProviders<Props>(({ i18n, pharmacy, locale }) => {
  return (
    <I18nProvider i18n={i18n} locale={locale}>
      <RestrictedPage>
        <Discussion pharmacy={pharmacy} />
      </RestrictedPage>
    </I18nProvider>
  );
});

import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-width: 0;
    width: 100%;
  `,
);

export const Box = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    background: white;
    border-radius: ${borderRadius};
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
    margin: ${utils.rem(16)};
    max-width: ${utils.rem(512)};
    padding: ${utils.rem(32)};
    width: 100%;
  `,
);

export const Text = styled.div(
  ({ theme: { utils } }) => css`
    color: ${utils.color('secondary', 'main')};
    font-size: ${utils.rem(18)};
    font-weight: 600;
    text-align: center;
  `,
);

export const ErrorInfo = styled.div(
  ({ theme: { utils } }) => css`
    color: ${utils.color('secondary', 'main', 0.7)};
    font-size: ${utils.rem(14)};
    font-weight: 400;
    margin-top: ${utils.rem(8)};
    text-align: center;
  `,
);

export const Svg = styled.svg(
  () => css`
    height: 100%;
    width: 100%;
  `,
);

import styled, { css } from 'styled-components';

export interface SwitchBaseProps {
  disabled?: boolean;
}

interface SwitchBoxProps {
  focus: boolean;
  error?: boolean;
}

interface SwitchInputProps {
  error?: boolean;
}

export const SwitchBase = styled.div<SwitchBaseProps>(({ theme: { utils } }) => ({
  color: utils.color('default', 'main'),
  padding: utils.em(0, 0),
  position: 'relative',
  textAlign: 'left',
  width: '100%',

  '&:not(:last-child)': {
    marginBottom: utils.em(16),
  },
}));

export const SwitchField = styled.label({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
});

export const SwitchLabel = styled.div(({ theme: { utils } }) => ({
  color: utils.color('secondary', 'main'),
  display: 'inline-block',
  fontSize: utils.rem(14),
  fontWeight: 500,
  lineHeight: 1,
  marginLeft: utils.rem(8),
}));

export const SwitchHelperText = styled.div(({ theme: { utils, borderRadius } }) => ({
  padding: utils.em(4, utils.fromRem(borderRadius), 4, 40),
}));

export const SwitchBox = styled.div<SwitchBoxProps>(({ theme: { utils }, focus, error }) => ({
  backgroundColor: utils.text('body', 0.3),
  borderRadius: utils.em(16),
  display: 'inline-block',
  height: utils.em(16),
  padding: utils.em(2),
  position: 'relative',
  transition: 'background-color .2s',
  width: utils.em(32),

  '&:hover': {
    cursor: 'pointer',
    boxShadow: `0 0 2px 2px ${utils.color(error ? 'error' : 'primary', 'main', 0.25)}`,
  },

  ...utils.css(focus, {
    boxShadow: `0 0 4px 4px ${utils.color('primary', 'main', 0.5)}`,
  }),

  ...utils.css(focus && error, {
    boxShadow: `0 0 4px 4px ${utils.color('error', 'main', 0.5)}`,
  }),

  ...utils.css(error, {
    backgroundColor: utils.color('error', 'main', 0.05),
  }),

  ['&::after']: {
    backgroundColor: utils.background('default'),
    borderRadius: '100%',
    content: '""',
    display: 'block',
    height: utils.em(12),
    position: 'relative',
    transform: 'translateX(0%)',
    transition: 'transform .2s, background-color .2s',
    width: utils.em(12),

    ...utils.css(error, {
      backgroundColor: utils.color('error', 'main'),
    }),
  },
}));

export const SwitchInput = styled.input<SwitchInputProps>(({ theme: { utils }, error }) => ({
  display: 'none',

  [`&:checked + ${SwitchBox}`]: {
    backgroundColor: utils.color('primary', 'main'),

    ...utils.css(error, {
      backgroundColor: utils.color('error', 'main'),
    }),

    ['&::after']: {
      backgroundColor: utils.color('primary', 'textContrast'),
      transform: `translateX(${utils.em(16)})`,

      ...utils.css(error, {
        backgroundColor: utils.color('error', 'textContrast'),
      }),
    },
  },

  [`&:disabled + ${SwitchBox}`]: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
}));

export const Required = styled.span(
  ({ theme: { utils } }) => css`
    color: ${utils.color('error', 'main')};
    display: inline-block;
    margin-left: ${utils.em(4)};
  `,
);

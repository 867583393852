import { PASSWORD_RULE } from '@config';
import { object, string } from 'yup';

interface Options {
  t: (key: string) => string;
}

export const ResetPasswordSchema = ({ t }: Options) =>
  object().shape({
    password: string()
      .matches(PASSWORD_RULE, {
        message: t(`shared:passwords.rule`),
      })
      .required()
      .label(t(`form.fields.new-password`)),
  });

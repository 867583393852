import { useMutation } from '@apollo/client';
import { ResetPasswordResults, RESET_PASSWORD } from '@graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@i18n';
import { PasswordField, SubmitButton } from '@shared';
import { Grid, Icon } from '@ui';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './ResetPasswordForm.styled';
import { ResetPasswordSchema } from './schema';

type ResetPasswordValues = {
  password: string;
};

export const ResetPasswordForm = () => {
  const { t } = useTranslation('reset-password');
  const params = new URLSearchParams(document.location.search);

  const resetPasswordToken = params.get('token') ?? '';

  const form = useForm<ResetPasswordValues>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(ResetPasswordSchema({ t })),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = form;

  const [resetPassword, { data, error }] = useMutation<ResetPasswordResults>(RESET_PASSWORD, {
    fetchPolicy: 'no-cache',
  });

  const handleSave: SubmitHandler<ResetPasswordValues> = async ({ password }) => {
    return resetPassword({
      variables: {
        input: { password, resetPasswordToken, context: 'patient' },
      },
    });
  };

  return (
    <Styled.Page>
      <Styled.Form>
        <Styled.Title>{t('title')}</Styled.Title>

        <FormProvider {...form}>
          {data?.resetPassword ? (
            <Styled.Message color="success">{t('patient.success.message')}</Styled.Message>
          ) : (
            <form onSubmit={handleSubmit(handleSave)}>
              {error && <Styled.Message color="error">{t('form.error.reset-fail')}</Styled.Message>}

              <Grid container>
                <Grid>
                  <PasswordField
                    name="password"
                    label={t('form.fields.new-password')}
                    prependIcon={<Icon icon="lock" />}
                  />
                </Grid>
              </Grid>

              <Styled.FormActions>
                <SubmitButton isDirty={isDirty} isSubmitting={isSubmitting} iconName="check">
                  {t('form.submit.text')}
                </SubmitButton>
              </Styled.FormActions>
            </form>
          )}
        </FormProvider>
      </Styled.Form>
    </Styled.Page>
  );
};

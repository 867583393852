import styled, { css } from 'styled-components';

interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
}

export const Item = styled.li<Props>(
  ({ theme: { utils }, isActive, isDisabled }) => css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: ${utils.rem(8, 0)};
    transition: transform 0.2s ease-out;
    will-change: transform;

    ${isActive
      ? css`
          color: ${utils.color('primary', 'main')};
          cursor: default;
          font-weight: 600;
        `
      : css`
          &:hover {
            cursor: pointer;
            transform: translateX(${utils.rem(2)});
          }
        `}

    ${isDisabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
  `,
);

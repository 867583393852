import styled, { css } from 'styled-components';

export const List = styled.ul(
  ({ theme: { utils } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    min-height: 100%;
    padding: ${utils.rem(16)};
  `,
);

export const ListItem = styled.li(
  ({ theme: { utils } }) => css`
    display: flex;
    list-style: none;
    padding: ${utils.rem(8, 0, 8)};
    width: 100%;
    z-index: 1;
  `,
);

export const LoaderContainer = styled.li(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('default')};
    list-style: none;
    padding: ${utils.rem(16)};
    text-align: center;
    width: 100%;
    z-index: 2;
  `,
);

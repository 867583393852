import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { uniqBy } from 'lodash';

interface MergePaginatedQueryOptions {
  readField: ReadFieldFunction;
  cursor: string | null;
}

export const mergeQueryResults = (
  existing: Reference[] | undefined,
  incoming: Reference[],
  { readField, cursor }: MergePaginatedQueryOptions,
) => {
  const results = existing?.slice(0) ?? [];
  const offset = results.findIndex((item) => readField('id', item) === cursor);

  if (offset < 0) {
    results.splice(0, 0, ...incoming);
  } else {
    results.splice(offset + 1, 0, ...incoming);
  }

  return uniqBy(results, '__ref');
};

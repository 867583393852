/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode, LazyQueryHookOptions, useLazyQuery, useMutation } from '@apollo/client';
import { CreateMessageResults } from '@graphql';
import { trim } from 'lodash';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { ChatMessage, ChatParticipant } from '../../../../types';
import { ChatChannel, ChatContext, ChatContextValues } from '../../context';
import { Attachment } from '../Attachments';

interface ChatDefinition {
  document: DocumentNode;
  resolver?: (data: any) => any;
}
export interface ChatDefinitions {
  messages: ChatDefinition;
  postMessage: ChatDefinition;
  readMessage: ChatDefinition;
  subscribeNewMessages: ChatDefinition;
}

interface Props {
  user?: ChatParticipant;
  definitions: ChatDefinitions;
}

const MESSAGES_LIMIT = 25;
const QUERY_OPTIONS: LazyQueryHookOptions = {
  notifyOnNetworkStatusChange: true,
};

export const Chat: FC<Props> = memo(({ children, user = null, definitions }) => {
  const [channel, setChannel] = useState<Nullable<ChatChannel>>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const [readMessage] = useMutation<ChatMessage>(definitions.readMessage.document);
  const [postMessage, { loading: posting }] = useMutation<CreateMessageResults>(
    definitions.postMessage.document,
  );
  const [fetchMessages, { loading: fetching, data: rawData, subscribeToMore, fetchMore, refetch }] =
    useLazyQuery(definitions.messages.document, QUERY_OPTIONS);

  const data = definitions.messages.resolver?.(rawData) ?? {};

  useEffect(() => {
    if (channel?.id) {
      fetchMessages({
        variables: { pharmacyId: channel.id, cursor: null, limit: MESSAGES_LIMIT },
      });
    }
  }, [channel?.id]);

  useEffect(() => {
    if (subscribeToMore) {
      return subscribeToMore({
        document: definitions.subscribeNewMessages.document,
        variables: {},
        updateQuery: (cache, { subscriptionData }) => {
          const data = definitions.subscribeNewMessages.resolver?.(subscriptionData.data);
          const { message } = data ?? {};

          return {
            messages: {
              ...cache.messages,
              messages: [message],
            },
          };
        },
      });
    }
  }, [channel?.id, subscribeToMore]);

  const context = useMemo<ChatContextValues>(() => {
    const hasMore = data.hasMore ?? false;
    const cursor = data.cursor ?? null;
    const messages = data.messages ?? [];

    return {
      actions: {
        fetchMoreMessages: () => {
          hasMore && fetchMore?.({ variables: { cursor, limit: MESSAGES_LIMIT } });
        },
        readMessage: (message) => {
          const input = { messageId: message.id };
          message.status !== 'read' && readMessage({ variables: { input } });
        },
        postMessage: async (content) => {
          const canPost = !posting && (trim(content) || attachments.length > 0);

          if (!canPost || !user || !channel) {
            throw new Error('bad_request');
          }

          const input = {
            recipientId: channel.recipient.id,
            content: trim(content),
            attachments: attachments.map(({ file }) => file),
          };
          await postMessage({ variables: { input } });
        },
        addAttachment: (file: File) => {
          !posting && setAttachments((state) => [...state, { isLoading: false, file }]);
        },
        removeAttachment: (index) => {
          if (posting) return;

          if (index !== undefined) {
            setAttachments((state) => state.filter((_, i) => i !== index));
          } else {
            setAttachments([]);
          }
        },
      },
      attachments,
      messages: [...messages].reverse(),
      posting,
      fetching,
      channel,
      setChannel,
      user,
      policies: {
        canPost: !fetching && !posting,
      },
      cursor,
    };
  }, [data, channel, user, posting, fetching, attachments]);
  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>;
});

import { useTranslation } from '@i18n';
import { Icon } from '@ui';
import React, { FC } from 'react';
import * as Styled from './DeletedText.styled';

interface Props {
  isDeleted?: boolean;
}

export const DeletedText: FC<Props> = ({ children, isDeleted }) => {
  const { t } = useTranslation('chat');

  return (
    <Styled.Text isDeleted={isDeleted}>
      {isDeleted ? (
        <Styled.DeletedText>
          <Icon icon="delete" />
          {t('message.deleted')}
        </Styled.DeletedText>
      ) : (
        children
      )}
    </Styled.Text>
  );
};

import styled from 'styled-components';

export interface InputLabelBaseProps {}

export const Base = styled.label<InputLabelBaseProps>(({ theme: { utils, borderRadius } }) => ({
  color: utils.color('secondary', 'main'),
  display: 'inline-block',
  fontSize: utils.rem(14),
  fontWeight: 500,
  lineHeight: 1,
  marginBottom: utils.rem(8),
  paddingLeft: `calc(${borderRadius} / 2)`,
}));

import { useMutation } from '@apollo/client';
import { DELETE_PATIENT } from '@graphql';
import { useTranslation } from '@i18n';
import { Title, useAuth, useConfirmDialog } from '@shared';
import { Button, Icon, Loader } from '@ui';
import React from 'react';
import * as Styled from './Profile.styled';

export const Profile = () => {
  const { t } = useTranslation('my-account');
  const { currentContext, logout } = useAuth();
  const { askForConfirmation } = useConfirmDialog();

  const [deleteMyAccount, { loading: deleting }] = useMutation(DELETE_PATIENT, {
    onCompleted() {
      logout();
    },
  });

  const handleDelete = () => {
    askForConfirmation({
      onConfirm: () => deleteMyAccount({ variables: { input: { patientId: currentContext?.id } } }),
      confirmText: t('delete.confirmation.confirm'),
      abortText: t('shared:actions.cancel'),
      content: t('delete.confirmation.text'),
    });
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <Title>{t('title')}</Title>
      </Styled.Header>
      <Title level="2">{t('delete.title')}</Title>
      <p>{t('delete.description')}</p>
      <Button color="error" icon="prepend" disabled={deleting} onClick={handleDelete}>
        {deleting ? <Loader /> : <Icon icon="delete" />}
        {t('delete.button')}
      </Button>
    </Styled.Container>
  );
};

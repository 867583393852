import { gql } from '@apollo/client';
import { ChatMessage, Discussion } from '../../../types';
import { MESSAGE_FRAGMENT } from '../fragments';

export interface NewMessageSubscriptionResults {
  newMessage: {
    message: ChatMessage;
    discussion: Discussion;
  };
}

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription {
    newMessage {
      message {
        ...Message
      }
      discussion {
        id
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

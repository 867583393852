import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { utils } }) => css`
    color: ${utils.color('secondary', 'main')};
  `,
);

export const Spec = styled.div(
  ({ theme: { utils } }) => css`
    align-items: center;
    display: flex;
    margin-bottom: ${utils.rem(16)};
  `,
);

export const SpecLabel = styled.div(
  ({ theme: { utils } }) => css`
    font-size: ${utils.rem(14)};
    flex: 0 0 ${utils.rem(100)};
    font-weight: 700;
    margin-right: ${utils.rem(16)};
    text-align: right;
  `,
);

export const SpecValue = styled.div(
  () => css`
    flex: 1;
  `,
);

export const Separator = styled.hr(
  ({ theme: { utils } }) => css`
    margin: ${utils.rem(16)} auto;
    max-width: 40%;
    opacity: 0.4;
  `,
);

export const DiscountPrice = styled.div(
  ({ theme: { utils } }) => css`
    font-size: ${utils.rem(24)};
    font-weight: 700;
    line-height: 1;
    margin-bottom: ${utils.rem(2)};
  `,
);

export const BasePrice = styled.div(
  ({ theme: { utils } }) => css`
    font-size: ${utils.rem(13)};
    line-height: 1;
  `,
);

export const QuantityInput = styled.input(
  ({ theme: { utils } }) => css`
    font-weight: 700;
    text-align: center;
  `,
);

export const QuantityIcon = styled.div(
  ({ theme: { utils } }) => css`
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  `,
);

export const ErrorMessage = styled.div(
  ({ theme: { utils } }) => css`
    border-left: 4px solid ${utils.color('error', 'main')};
    color: ${utils.color('error', 'main')};
    font-size: ${utils.rem(15)};
    margin: ${utils.rem(0, -32, 16)};
    padding: ${utils.rem(0, 32, 0, 28)};
  `,
);

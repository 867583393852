import styled, { Color } from 'styled-components';
import { Icon } from '../Icon/Icon.styled';

export interface BaseProps {
  color?: Color;
  icon?: undefined | 'prepend' | 'append' | 'both-sides';
  outlined?: boolean;
  disabled?: boolean;
}

export const Base = styled.div<BaseProps>((props) => {
  const {
    theme: { utils, borderRadius },
    color = 'secondary',
    outlined,
    icon,
    disabled,
  } = props;

  const fontSize = 12;

  const fs = 6;
  const y = 1;
  const x = 1;

  return {
    alignItems: 'center',
    backgroundColor: utils.color(color, 'main', outlined ? 0.05 : 1),
    borderRadius,
    border: '1px solid transparent',
    color: utils.color(color, outlined ? 'main' : 'textContrast'),
    display: 'inline-flex',
    fontFamily: utils.font('body'),
    fontSize: utils.em(fontSize),
    fontWeight: 400,
    lineHeight: 1,
    position: 'relative',
    transition: 'background-color .2s, color .2s',

    ...utils.css(disabled, {
      opacity: 0.4,
      pointerEvents: 'none',
    }),

    ...utils.css(outlined, {
      borderColor: utils.color(color!, 'main'),
    }),

    ...utils.css(!icon, {
      padding: utils.em(y, fs),
    }),

    ...utils.css(icon === 'prepend', {
      padding: utils.em(y, fs, y, x),
      [Icon]: {
        marginRight: utils.em(fs),
      },
    }),

    ...utils.css(icon === 'append', {
      padding: utils.em(y, x, y, fs),
      [Icon]: {
        marginLeft: utils.em(fs),
      },
    }),

    ...utils.css(icon === 'both-sides', {
      padding: utils.em(y, x),
      [`${Icon}:first-child`]: {
        marginRight: utils.em(fs),
      },
      [`${Icon}:last-child`]: {
        marginLeft: utils.em(fs),
      },
    }),
  };
});

import { useMutation } from '@apollo/client';
import { getValidationErrors, SIGNUP, SignUpResults } from '@graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@i18n';
import {
  AuthLink,
  AuthLinks,
  PasswordField,
  SelectField,
  SubmitButton,
  SwitchField,
  TextField,
} from '@shared';
import { Button, Grid } from '@ui';
import { camelCase, omitBy } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SignupSchema } from './schema';
import * as Styled from './SignupForm.styled';

enum GenderEnum {
  female,
  male,
  ungender,
}

type SignUpValues = {
  locale: string;
  gender: GenderEnum;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  birthdate: string;
  niss: string;
  phone: string;
  acceptTermsAndConditions: boolean;
  acceptDataProcessing: boolean;
};

interface Props {
  locale: string;
}

export const SignupForm: FC<Props> = ({ locale }) => {
  const { t } = useTranslation('signup');

  const [isCreated, setIsCreated] = useState(false);

  const form = useForm<SignUpValues>({
    defaultValues: {
      acceptDataProcessing: false,
      acceptTermsAndConditions: false,
    },
    resolver: yupResolver(SignupSchema({ t })),
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty },
  } = form;

  const [signUp] = useMutation<SignUpResults>(SIGNUP);

  const handleSave: SubmitHandler<SignUpValues> = async ({ passwordConfirm, ...input }) => {
    return signUp({
      variables: {
        input: omitBy(input, (v) => v === ''),
      },
    })
      .then(() => {
        setIsCreated(true);
      })
      .catch((errors) => {
        const validationErrors = getValidationErrors(errors);
        validationErrors.forEach(({ field, message }) => {
          setError(
            camelCase(field) as keyof SignUpValues,
            {
              message: t(`shared:forms.validation.${message}`, {
                path: t(field),
              }),
              type: 'focus',
            },
            { shouldFocus: true },
          );
        });
      });
  };

  const GENDER_OPTIONS = useMemo(
    () => ['woman', 'man', 'prefer_not_to_respond'].map((value) => ({ value, label: t(value) })),
    [],
  );

  return (
    <Styled.Page>
      <Styled.Form>
        <Styled.Title>{t('title')}</Styled.Title>
        {isCreated && (
          <Styled.SuccessMessage>
            <Styled.SuccessMessageText>{t('messages.success')}</Styled.SuccessMessageText>
            <Button color="primary" onClick={() => document.location.assign('/signin')}>
              {t('messages.success_button')}
            </Button>
          </Styled.SuccessMessage>
        )}
        {!isCreated && (
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(handleSave)}>
              <Grid container>
                <TextField name="locale" type="hidden" defaultValue={locale} />
                <Grid size={4}>
                  <SelectField
                    name="gender"
                    label={t('gender')}
                    options={GENDER_OPTIONS}
                    required
                  />
                </Grid>
                <Grid size={4}>
                  <TextField name="firstName" label={t('first_name')} required />
                </Grid>
                <Grid size={4}>
                  <TextField name="lastName" label={t('last_name')} required />
                </Grid>
              </Grid>

              <Grid container>
                <Grid size={6}>
                  <TextField name="email" label={t('email')} required />
                </Grid>
                <Grid size={6}>
                  <TextField name="phoneNumber" label={t('phone')} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid size={6}>
                  <PasswordField name="password" label={t('password')} required />
                </Grid>
                <Grid size={6}>
                  <PasswordField name="passwordConfirm" label={t('password_confirm')} required />
                </Grid>
              </Grid>

              <Grid container>
                <Grid size={6}>
                  <TextField name="birthdate" label={t('birthdate')} type="date" />
                </Grid>
                <Grid size={6}>
                  <TextField name="niss" label={t('niss')} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid>
                  <SwitchField
                    name="acceptTermsAndConditions"
                    label={t('accept_terms_and_services')}
                    required
                  />
                  <Styled.Link href="https://www.easypharm.be/site/legal/" target="_blank">
                    <Styled.Label>{t('terms_and_services')}</Styled.Label>
                  </Styled.Link>
                </Grid>
              </Grid>

              <Grid container>
                <Grid>
                  <SwitchField name="acceptDataProcessing" label={t('accept_data_processing')} />
                  <Styled.Label>{t('data_processing')}</Styled.Label>
                </Grid>
              </Grid>

              <Styled.FormActions>
                <SubmitButton isDirty={isDirty} isSubmitting={isSubmitting} iconName="check">
                  {t('submit')}
                </SubmitButton>
              </Styled.FormActions>
            </form>

            <AuthLinks>
              <AuthLink href="/signin">{t('auth:links.sign-in')}</AuthLink>
            </AuthLinks>
          </FormProvider>
        )}
      </Styled.Form>
    </Styled.Page>
  );
};

import React, {
  FC,
  FormEventHandler,
  HTMLProps,
  KeyboardEventHandler,
  memo,
  useLayoutEffect,
  useRef,
} from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './AutosizeTextarea.styled';

interface InputProps extends Styled.InputBaseProps, Omit<HTMLProps<HTMLTextAreaElement>, 'as'> {
  autoFocus?: boolean;
  value?: string;
  onSubmit?: FormEventHandler;
}

// TODO: Improve INPUT component to allow inline component (input prop)
export const AutosizeTextarea: FC<InputProps> = memo(({ onSubmit, maxHeight, value, ...props }) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { utils } = useTheme();

  const preventEnter: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit?.(event);
    }
  };

  useLayoutEffect(() => {
    if (ref.current) {
      const element = ref.current;
      if (value == '') {
        element.style.setProperty('height', utils.rem(40));
      } else {
        element.style.setProperty('height', utils.rem(40));
        element.style.setProperty('height', utils.rem(element.scrollHeight));
      }
    }
  }, [value]);

  return (
    <Styled.Textarea
      {...props}
      onKeyDown={preventEnter}
      maxHeight={maxHeight}
      ref={ref}
      value={value}
    />
  );
});

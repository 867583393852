import { useMutation } from '@apollo/client';
import { ForgotPasswordResults, FORGOT_PASSWORD } from '@graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@i18n';
import { AuthLink, AuthLinks, SubmitButton, TextField } from '@shared';
import { Grid, Icon } from '@ui';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './ForgotPasswordForm.styled';
import { ForgotPasswordSchema } from './schema';

type ForgotPasswordValues = {
  email: string;
};

export const ForgotPasswordForm = () => {
  const { t } = useTranslation('forgot-password');

  const form = useForm<ForgotPasswordValues>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(ForgotPasswordSchema({ t })),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = form;

  const [forgotPassword, { data, error }] = useMutation<ForgotPasswordResults>(FORGOT_PASSWORD, {
    fetchPolicy: 'no-cache',
  });

  const handleSave: SubmitHandler<ForgotPasswordValues> = async ({ email }) => {
    return forgotPassword({
      variables: {
        input: { email, context: 'patient' },
      },
    });
  };

  return (
    <Styled.Page>
      <Styled.Form>
        <Styled.Title>{t('title')}</Styled.Title>

        <FormProvider {...form}>
          {data?.forgotPassword ? (
            <Styled.Message color="success">
              {t('success.message', { email: form.getValues('email') })}
            </Styled.Message>
          ) : (
            <form onSubmit={handleSubmit(handleSave)}>
              {error && <Styled.Message color="error">{t('form.error.email')}</Styled.Message>}

              <Grid container>
                <Grid>
                  <TextField
                    name="email"
                    label={t('form.fields.email')}
                    prependIcon={<Icon icon="person" />}
                  />
                </Grid>
              </Grid>

              <Styled.FormActions>
                <SubmitButton isDirty={isDirty} isSubmitting={isSubmitting} iconName="check">
                  {t('form.submit.text')}
                </SubmitButton>
              </Styled.FormActions>
            </form>
          )}

          <AuthLinks>
            <AuthLink href="/signin">{t('auth:links.sign-in')}</AuthLink>
          </AuthLinks>
        </FormProvider>
      </Styled.Form>
    </Styled.Page>
  );
};

import { ConfirmDialogProvider, RestrictedPage, WithProviders } from '@shared';
import '@shared/utils/date';
import React from 'react';
import { I18nProvider } from '../../i18n/provider';
import { Profile } from './components';

interface Props {
  locale: string;
  i18n: Record<string, any>;
}

export const MyAccount = WithProviders<Props>(({ i18n, locale }) => {
  return (
    <I18nProvider i18n={i18n} locale={locale}>
      <ConfirmDialogProvider>
        <RestrictedPage>
          <Profile />
        </RestrictedPage>
      </ConfirmDialogProvider>
    </I18nProvider>
  );
});

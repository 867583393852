import { Icon, useToggle } from '@ui';
import React from 'react';
import { TextField, TextFieldProps } from '../TextField';

export const PasswordField = (props: TextFieldProps) => {
  const [showPassword, toggleShowPassword] = useToggle(false);

  return (
    <TextField
      {...props}
      autoComplete="off"
      type={showPassword ? 'text' : 'password'}
      appendIcon={
        <div role="button" onClick={() => toggleShowPassword()}>
          <Icon icon={showPassword ? 'visibility_off' : 'visibility'} />
        </div>
      }
    />
  );
};

import { ClearIndicatorProps, DropdownIndicatorProps } from 'react-select';
import styled, { css } from 'styled-components';

export interface InputBaseProps {}

interface InputControlProps {
  focus: boolean;
  filled: boolean;
  error?: boolean;
  disabled?: boolean;
}

interface InputAdornmentProps {
  position: 'prepend' | 'append';
}

export const Base = styled.div<InputBaseProps>(({ theme: { utils } }) => ({
  position: 'relative',
  textAlign: 'left',
  width: '100%',

  '&:not(:last-child)': {
    marginBottom: utils.rem(16),
  },
}));

export const Control = styled.div<InputControlProps>(
  ({ theme: { colors, utils, borderRadius }, focus, filled, error, disabled }) => ({
    alignItems: 'flex-start',
    backgroundColor: utils.background('default'),
    border: `1px solid ${colors.palette[error ? 'error' : 'default'].main}`,
    borderRadius,
    color: utils.color('secondary', 'main'),
    display: 'flex',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',

    ...utils.css(filled, {
      color: utils.color('secondary', 'main'),
    }),

    ...utils.css(focus, {
      boxShadow: `0 0 4px 4px ${utils.color(error ? 'error' : 'primary', 'main', 0.5)}`,
    }),

    ...utils.css(disabled, {
      opacity: 0.4,
      cursor: 'not-allowed',
    }),
  }),
);

export const Field = styled.div({
  width: '100%',
});

export const Label = styled.label(() => ({}));

export const Adornment = styled.div<InputAdornmentProps>(({ theme: { utils }, position }) => {
  const padding = 12;
  const paddingRight = position === 'prepend' ? 0 : 16;
  const paddingLeft = position === 'append' ? 0 : 16;
  return {
    alignItems: 'center',
    color: utils.color('secondary', 'main'),
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 1,
    padding: utils.rem(padding, paddingRight, padding, paddingLeft),
    zIndex: 1,
  };
});

export const HelperText = styled.div(({ theme: { utils, borderRadius } }) => ({
  padding: utils.rem(4, utils.fromRem(borderRadius) / 2),
}));

export const Required = styled.span(
  ({ theme: { utils } }) => css`
    color: ${utils.color('error', 'main')};
    display: inline-block;
    margin-left: ${utils.em(4)};
  `,
);

export const DropdownIndicator = styled.div<Pick<DropdownIndicatorProps, 'isFocused'>>(
  ({ theme: { utils }, isFocused }) => css`
    color: ${utils.color('secondary', 'main', isFocused ? 0.7 : 0.4)};
    display: flex;
    padding: ${utils.rem(7, 16, 7, 7)};
  `,
);

export const ClearIndicator = styled.div<Pick<ClearIndicatorProps, 'isFocused'>>(
  ({ theme: { utils } }) => css`
    color: ${utils.color('secondary', 'main', 0.7)};
    display: flex;
  `,
);

import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { utils, breakpoints } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${utils.rem(16, 8)};

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      padding: ${utils.rem(32, 16)};
    }
  `,
);

export const Avatar = styled.div(
  () => css`
    position: relative;
  `,
);

export const Image = styled.img(
  ({ theme: { utils, breakpoints } }) => css`
    border: 2px solid currentColor;
    border-radius: 100%;
    display: block;
    height: ${utils.rem(32)};
    margin-bottom: ${utils.rem(8)};
    width: ${utils.rem(32)};

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      height: ${utils.rem(80)};
      width: ${utils.rem(80)};
    }
  `,
);

export const Name = styled.div(
  ({ theme: { utils, breakpoints } }) => css`
    display: none;
    font-size: ${utils.rem(18)};
    font-weight: 700;

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      display: block;
    }
  `,
);

export const Info = styled.div(
  ({ theme: { utils, breakpoints } }) => css`
    display: none;
    font-size: ${utils.rem(14)};

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      display: block;
    }
  `,
);

export const Icon = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    background-color: ${utils.background('default')};
    border-radius: ${borderRadius};
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(25%, -25%);
  `,
);

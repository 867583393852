import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image';

export const PARTICIPANT_FRAGMENT = gql`
  fragment Participant on Participant {
    avatar {
      ...Image
    }
    fullName
    id
    role
  }
  ${IMAGE_FRAGMENT}
`;

import { PASSWORD_RULE } from '@config';
import { bool, object, ref, string } from 'yup';

interface Options {
  t: (key: string) => string;
}

export const SignupSchema = ({ t }: Options) =>
  object().shape({
    gender: string().required().label(t('gender')),
    firstName: string().required().label(t('first_name')),
    lastName: string().required().label(t('last_name')),
    email: string().email().required().label(t('email')),
    password: string()
      .matches(PASSWORD_RULE, {
        message: t(`shared:passwords.rule`),
      })
      .required()
      .label(t(`password`)),
    passwordConfirm: string()
      .trim()
      .oneOf([ref('password')])
      .required()
      .label(t('password_confirm')),
    birthdate: string().label(t('birthdate')),
    niss: string().label(t('niss')),
    phoneNumber: string().label(t('phone')),
    acceptTermsAndConditions: bool()
      .oneOf([true], t('shared:forms.validation.must_be_accepted'))
      .required()
      .label(t('terms_services')),
    acceptDataProcessing: bool().label(t('data_processing')),
  });

import styled, { Color } from 'styled-components';

export interface AvatarProps {
  color?: Color;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge' | 'wide';
  rounded?: boolean;
}

const sizes = {
  tiny: 12,
  small: 20,
  medium: 32,
  large: 40,
  huge: 48,
  wide: 64,
};

export const Avatar = styled.div<AvatarProps>(
  ({ theme: { utils, borderRadius }, color, rounded, size = 'medium' }) => ({
    borderRadius: rounded ? '100%' : borderRadius,
    border: `none`,
    fontSize: utils.rem(sizes[size as keyof typeof sizes]),
    fontWeight: 600,
    overflow: 'hidden',
    position: 'relative',
    width: utils.em(16),

    ...utils.css(!!color, {
      backgroundColor: utils.color(color!, 'main', 0.1),
      border: `2px solid ${utils.color(color!, 'main')}`,
      color: utils.color(color!, 'main'),
    }),

    '&:before': {
      content: '""',
      display: 'block',
      position: 'relative',
      paddingTop: '100%',
    },
  }),
);

export const AvatarContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
});

export const AvatarImage = styled.img(() => ({
  height: '100%',
  width: '100%',
}));

export const AvatarName = styled.img(({ theme: { utils } }) => ({
  fontSize: utils.rem(16),
}));

import styled, { Color, keyframes } from 'styled-components';

export interface BaseProps {
  color?: Color;
}

export interface SpanProps {
  delay?: number;
}

export const Base = styled.div<BaseProps>(({ theme: { utils }, color }) => ({
  alignItems: 'center',
  color: 'currentcolor',
  display: 'flex',
  fontSize: 'inherit',
  justifyContent: 'center',
  padding: utils.rem(4),

  ...utils.css(!!color, {
    color: utils.color(color!, 'main'),
  }),
}));

const fade = keyframes`
  0%,  80%, 100% { opacity: 0; }
  40% { opacity: 1; }
`;

export const Span = styled.span<SpanProps>`
  animation: 1s ${fade} ease-in-out ${(props) => props.delay}ms infinite;
  background-color: currentColor;
  border-radius: 100%;
  display: block;
  height: 0.5em;
  margin: 0 0.125em;
  width: 0.5em;
`;

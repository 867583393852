import { TMessageStatus } from '@types';
import { Icon } from '@ui';
import React, { FC } from 'react';
import { Color } from 'styled-components';
import * as Styled from './MessageStatus.styled';

interface Props {
  color?: Color;
  invert?: boolean;
  status: TMessageStatus;
}

export const MessageStatus: FC<Props> = ({ status, color = 'primary', invert }) => {
  const isRead = ['read'].includes(status);

  return (
    <Styled.Container>
      <Styled.Status color={color} invert={invert} isActive={isRead}>
        <Icon icon="check" />
      </Styled.Status>
      <Styled.Status color={color} invert={invert} isActive={isRead}>
        <Icon icon="check" />
      </Styled.Status>
    </Styled.Container>
  );
};

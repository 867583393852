import { useTranslation } from '@i18n';
import { ErrorState, getTypeFromFilename } from '@shared';
import { ChatMessageAttachment } from '@types';
import { Icon, Loader } from '@ui';
import React, { FC, HTMLAttributes, memo, useEffect, useState } from 'react';
import { FetchSecuredAttachment } from '../../../utils';
import * as Styled from './Attachment.styled';

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  attachment: ChatMessageAttachment;
}

export const MessageAttachment: FC<Props> = memo(({ attachment, ...props }) => {
  const isImage = getTypeFromFilename(attachment.fileName) === 'image';

  const { t } = useTranslation('chat');
  const [loaded, setLoaded] = useState(false);
  const [image, setImage] = useState<Nullable<string>>(null);

  useEffect(() => {
    setLoaded(false);
    FetchSecuredAttachment(attachment.thumbUrl)
      .then((image) => {
        setImage(image);
      })
      .finally(() => setLoaded(true));
  }, [attachment]);

  if (!loaded) {
    return (
      <Styled.Wrapper isImage>
        <Styled.LoadingFile>
          <Loader />
        </Styled.LoadingFile>
      </Styled.Wrapper>
    );
  }

  if (!image) {
    return (
      <Styled.File>
        <ErrorState error={t('attachment.loading-failed')} />
      </Styled.File>
    );
  }

  return (
    <Styled.Wrapper isImage={isImage}>
      <Styled.Link key={attachment.id} href={attachment.originalUrl} target="_blank" {...props}>
        {isImage ? (
          <Styled.Image src={image} alt={attachment.fileName} />
        ) : (
          <Styled.File>
            <Icon icon="description" size="3x" />
            <Styled.FileName>{attachment.fileName}</Styled.FileName>
          </Styled.File>
        )}
      </Styled.Link>
    </Styled.Wrapper>
  );
});

import styled from 'styled-components';

export interface BaseProps {}
export interface MenuProps {
  left: number;
  top: number;
  width?: number;
}
export interface MenuContainerProps {
  hideBackdrop?: boolean;
}

export const Base = styled.div<BaseProps>({
  position: 'relative',
});

export const MenuContainer = styled.div<MenuContainerProps>(
  ({ theme: { utils, zIndex }, hideBackdrop }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: zIndex.tooltip,

    ...utils.css(!hideBackdrop, {
      backgroundColor: utils.color('secondary', 'main', 0.4),
    }),
  }),
);

export const Menu = styled.div<MenuProps>(
  ({ theme: { utils, colors, borderRadius }, left, top, width }) => ({
    background: colors.background.default,
    boxShadow: `${utils.rem(0, 8, 32)} rgba(0, 0, 0, 0.1)`,
    borderRadius,
    left: utils.rem(left),
    margin: 0,
    minWidth: width && utils.rem(width),
    padding: utils.rem(16),
    overflow: 'auto',
    position: 'absolute',
    top: utils.rem(top),
    zIndex: 1,
  }),
);

import styled, { css } from 'styled-components';

export const Actions = styled.div(
  () => css`
    justify-content: center;
    text-align: center;
  `,
);

export const Body = styled.div(
  () => css`
    opacity: 0.7;
    text-align: center;
  `,
);

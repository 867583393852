import { useTranslation } from '@i18n';
import { SidebarNavigation, SidebarUserinfo } from '@shared';
import '@shared/utils/date';
import { Loader } from '@ui';
import React, { FC, MouseEventHandler, useEffect } from 'react';
import { WithAuth } from '../../hoc';
import { useAuth } from '../../hooks';
import * as Styled from './RestrictedPage.styled';

export const RestrictedPage: FC = WithAuth(({ children }) => {
  const { t } = useTranslation('sidebar');
  const { logout, fetching: loading, currentContext: currentUser } = useAuth();

  const handleLogout: MouseEventHandler = (event) => {
    event.preventDefault();
    logout();
  };

  useEffect(() => {
    if (!loading && !currentUser) {
      logout();
    }
  }, [currentUser, loading]);

  if (loading) {
    return (
      <Styled.Container>
        <Loader />
      </Styled.Container>
    );
  }

  if (!currentUser) {
    return null;
  }

  return (
    <Styled.Container>
      <Styled.Sidebar>
        <SidebarUserinfo
          name={currentUser.fullName}
          info={currentUser.email}
          avatar={currentUser?.avatar?.thumbUrl}
        />
        <SidebarNavigation>
          <SidebarNavigation.Item label={t('menu.account')} icon="person" href="/account" />
          <SidebarNavigation.Item
            label={t('menu.orders')}
            icon="shopping_basket"
            href="/account/order"
          />
          <SidebarNavigation.Separator />
          <SidebarNavigation.Item
            label={t('menu.logout')}
            icon="power_settings_new"
            href="/logout"
            onClick={handleLogout}
          />
        </SidebarNavigation>
      </Styled.Sidebar>
      <Styled.Body>{children}</Styled.Body>
    </Styled.Container>
  );
});

import { useMutation } from '@apollo/client';
import { CreateMessageResults, CREATE_MESSAGE } from '@graphql';
import { useTranslation } from '@i18n';
import { SubmitButton, useAuth } from '@shared';
import { Promotion } from '@types';
import { Button, Dialog, Icon, Input, Loader } from '@ui';
import React, { ChangeEvent, useState } from 'react';
import { WithAuth } from '../../../shared/hoc';
import * as Styled from './PromotionOrderDialog.styled';

interface Props {
  promotion: Promotion;
  pharmacyId: string;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const InputField = (props: any) => <Styled.QuantityInput {...props} />;

export const PromotionOrderDialog = WithAuth(
  ({ promotion, onCancel, pharmacyId, onSubmit }: Props) => {
    const { t } = useTranslation('promotion');
    const { isLoggedIn, fetching } = useAuth();
    const [quantity, setQuantity] = useState(1);

    const [createMessage, { loading: isSubmitting, error }] = useMutation<CreateMessageResults>(
      CREATE_MESSAGE,
      {
        onCompleted(data) {
          data.createMessage && onSubmit?.();
        },
      },
    );

    const increment = () => {
      setQuantity((value) => Math.min(99, value + 1));
    };

    const decrement = () => {
      setQuantity((value) => Math.max(1, value - 1));
    };

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value, 10);

      setQuantity(Math.max(1, Math.min(99, value)));
    };

    const handleQuantityBlur = () => {
      if (isNaN(quantity)) {
        setQuantity(1);
      }
    };

    const handleRedirect = () => {
      const url = new URL(document.location.href);
      url.pathname = '/signin';
      url.searchParams.append('referer', document.location.href);

      document.location.assign(url.toString());
    };

    const handleSubmit = () => {
      const { cnk, title, discount_price } = promotion;
      const content = t('message.body', {
        quantity: `${quantity}`,
        body_cnk: cnk ? t('message.body_cnk', {cnk}) : '',
        title,
        price: discount_price!,
      });

      const input = { content, recipientId: pharmacyId };
      createMessage({ variables: { input } });
    };

    return (
      <>
        <Dialog.Title>{promotion.title}</Dialog.Title>
        <Dialog.Body>
          {fetching && <Loader />}
          {!fetching && (
            <>
              {isLoggedIn && (
                <Styled.Container>
                  {error && <Styled.ErrorMessage>{error?.message}</Styled.ErrorMessage>}
                  <Styled.Spec>
                    <Styled.SpecLabel>{t('dialog.labels.price')}</Styled.SpecLabel>
                    <Styled.SpecValue>
                      <Styled.DiscountPrice>
                        {promotion.discount_type !== 'free'
                          ? promotion.discount_price
                          : promotion.discount_value}
                      </Styled.DiscountPrice>
                      <Styled.BasePrice>
                        {t(
                          `dialog.infotext.${
                            promotion.discount_type !== 'free' ? 'old' : 'base'
                          }-price`,
                          { price: promotion.base_price },
                        )}
                      </Styled.BasePrice>
                    </Styled.SpecValue>
                  </Styled.Spec>
                  <Styled.Spec>
                    <Styled.SpecLabel>{t('dialog.labels.quantity')}</Styled.SpecLabel>
                    <Styled.SpecValue>
                      <Input
                        type="number"
                        prependIcon={
                          <Styled.QuantityIcon onClick={decrement}>
                            <Icon icon="remove" />
                          </Styled.QuantityIcon>
                        }
                        appendIcon={
                          <Styled.QuantityIcon onClick={increment}>
                            <Icon icon="add" />
                          </Styled.QuantityIcon>
                        }
                        value={quantity}
                        onChange={handleQuantityChange}
                        onBlur={handleQuantityBlur}
                        input={InputField}
                      />
                    </Styled.SpecValue>
                  </Styled.Spec>
                </Styled.Container>
              )}
              {!isLoggedIn && (
                <Button color="primary" onClick={handleRedirect}>
                  Please login
                </Button>
              )}
            </>
          )}
        </Dialog.Body>
        {isLoggedIn && (
          <Dialog.Actions align="center">
            <Button color="default" onClick={onCancel}>
              {t('shared:actions.cancel')}
            </Button>
            <SubmitButton
              color="primary"
              isSubmitting={isSubmitting}
              isDirty
              onClick={handleSubmit}>
              {t('shared:actions.book')}
            </SubmitButton>
          </Dialog.Actions>
        )}
      </>
    );
  },
);

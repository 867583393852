import { ApolloProvider } from '@apollo/client';
import React, { FC } from 'react';
import { GraphqlClient } from '../../api';
import { ContactForm, Locale } from './ContactForm';

interface Props {
  pharmacyId: string;
  i18n: Locale;
}

export const ContactFormProvider: FC<Props> = ({ pharmacyId, i18n }) => {
  return (
    <ApolloProvider client={GraphqlClient('public')}>
      <ContactForm pharmacyId={pharmacyId} i18n={i18n} />
    </ApolloProvider>
  );
};

import { Icon } from '@ui';
import React, { useEffect, useState } from 'react';
import * as Styled from './Attachments.styled';

interface Props {
  file: File;
  disabled?: boolean;
  onDelete: () => void;
}

export const AttachmentBox = ({ file, disabled, onDelete }: Props) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result as string);
    };
  }, [file]);

  return (
    <Styled.File image={image}>
      {!disabled && (
        <Styled.DeleteFile onClick={onDelete}>
          <Icon icon="close" />
        </Styled.DeleteFile>
      )}
    </Styled.File>
  );
};

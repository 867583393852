import { gql } from '@apollo/client';

export interface AccessRenewalResults {
  accessRenewal: {
    accessToken: string;
    refreshToken: string;
  };
}

export const ACCESS_RENEWAL = gql`
  mutation accessRenewal($input: AccessRenewalInput!) {
    accessRenewal(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

import { useTranslation } from '@i18n';
import { useLocalStorage } from '@shared';
import { AutosizeTextarea, Button, Icon, Input, Loader } from '@ui';
import { trim } from 'lodash';
import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useChat } from '../../hooks';
import { AttachmentButton, Attachments } from '../Attachments';
import * as Styled from './MessageInput.styled';

export interface InitialMessage {
  message: string;
}

interface Props {
  initialMessage?: InitialMessage;
  disabled?: boolean;
  components?: {
    prependInput?: JSX.Element;
    appendInput?: JSX.Element;
  };
}

const InputField = (p: unknown) => (
  <AutosizeTextarea key="chat-message-input" maxHeight={100} autoFocus {...p} />
);

export const MessageInput: FC<Props> = ({ initialMessage, components }) => {
  const { t } = useTranslation('chat');

  const {
    actions: { postMessage, removeAttachment, addAttachment },
    posting,
    channel,
    policies: { canPost },
    attachments,
  } = useChat();

  const [message, setMessage, destroyMessage] = useLocalStorage(
    `message.${channel?.id}`,
    initialMessage?.message ?? '',
    sessionStorage,
  );

  const canPostMessage = canPost && (trim(message) !== '' || attachments.length > 0);

  const handlePost = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (canPost) {
        try {
          await postMessage(trim(message));
          destroyMessage();
          removeAttachment();
        } catch (error) {
          //TODO: to be precised
          console.error(error);
        }
      }
    },
    [canPost, message, attachments],
  );

  useEffect(() => {
    setMessage(initialMessage?.message ?? '');
  }, [initialMessage]);

  return (
    <>
      <Attachments attachments={attachments} deletable={!posting} onDelete={removeAttachment} />
      <Styled.Container onSubmit={handlePost}>
        {components?.prependInput}

        <AttachmentButton disabled={!canPost} onAdd={addAttachment} />

        <Styled.Input>
          <Input
            name="message"
            placeholder={t('message-input.placeholder')}
            disabled={!canPost}
            value={message ?? ''}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => setMessage(target.value)}
            onSubmit={handlePost}
            input={InputField}
          />
        </Styled.Input>

        {components?.appendInput}

        <Button icon="only" color="primary" type="submit" disabled={!canPostMessage}>
          <Styled.ButtonLabel>{t('message-input.post')}</Styled.ButtonLabel>
          {posting ? <Loader /> : <Icon icon="send" />}
        </Button>
      </Styled.Container>
    </>
  );
};

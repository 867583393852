import { useTranslation } from '@i18n';
import { Button, Dialog } from '@ui';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import * as Styled from './ConfirmDialogProvider.styled';

interface Props {}

interface Options {
  onAbort?: () => void;
  onConfirm?: () => void;
  content?: Nullable<ReactNode>;
  titleText?: Nullable<string>;
  confirmText?: Nullable<string>;
  abortText?: Nullable<string>;
}

const DEFAULT_OPTIONS = {
  onAbort: () => null,
  onConfirm: () => null,
  content: null,
  titleText: null,
  confirmText: null,
  abortText: null,
};

export const ConfirmDialogProvider: FC<Props> = ({ children }) => {
  const { t } = useTranslation('shared');
  const [isOpen, setOpen] = useState(false);
  const [{ content, titleText, confirmText, abortText, onAbort, onConfirm }, setOptions] =
    useState<Options>(DEFAULT_OPTIONS);

  const handleOpen = useCallback((options: Options = DEFAULT_OPTIONS) => {
    setOptions((state) => ({ ...state, ...options }));
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickYes = () => {
    handleClose();
    onConfirm?.();
  };

  const handleClickNo = () => {
    handleClose();
    onAbort?.();
  };

  const handleDialogCloseEnd = () => {
    setOptions(DEFAULT_OPTIONS);
  };

  const context = useMemo<ConfirmDialogContextInterface>(
    () => ({
      askForConfirmation: handleOpen,
      abortConfirmation: handleClose,
    }),
    [handleClose, handleOpen],
  );

  return (
    <>
      <ConfirmDialogContext.Provider value={context}>{children}</ConfirmDialogContext.Provider>
      <Dialog
        show={isOpen}
        size="mobile"
        onCloseEnd={handleDialogCloseEnd}
        preventBackdropClose
        preventEscapeClose
        hideCloseButton>
        <Dialog.Title>{titleText || t('confirm-dialog.title')}</Dialog.Title>
        <Styled.Body as={Dialog.Body}>{content}</Styled.Body>
        <Styled.Actions as={Dialog.Actions}>
          <Button onClick={handleClickNo} color="default">
            {abortText || t('confirm-dialog.cancel')}
          </Button>
          <Button onClick={handleClickYes} color="error">
            {confirmText || t('confirm-dialog.confirm')}
          </Button>
        </Styled.Actions>
      </Dialog>
    </>
  );
};

interface ConfirmDialogContextInterface {
  askForConfirmation: (options?: Options) => void;
  abortConfirmation: () => void;
}

export const ConfirmDialogContext = createContext<ConfirmDialogContextInterface>({
  askForConfirmation: () => null,
  abortConfirmation: () => null,
});

export const useConfirmDialog = () => {
  return useContext(ConfirmDialogContext);
};

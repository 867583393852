import { useTranslation } from '@i18n';
import { FC, ReactNode } from 'react';
import * as Styled from './ErrorState.styled';

interface Props {
  error?: ReactNode;
}

export const ErrorState: FC<Props> = ({ error }) => {
  const { t } = useTranslation('shared');

  return (
    <Styled.Container>
      <Styled.Box>
        <Styled.Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 150">
          <path
            d="M114.896 74.1771C114.896 87.4229 109.485 99.3628 100.81 107.945C92.3217 116.433 80.5684 121.564 67.6024 121.564C54.7296 121.564 42.9763 116.34 34.3945 107.945C25.7194 99.3628 20.3091 87.4229 20.3091 74.1771C20.3091 47.9652 41.4838 26.7905 67.6024 26.7905C93.7209 26.7905 114.896 48.0585 114.896 74.1771Z"
            fill="#EAEEF9"
          />
          <path
            d="M110.791 43.1143C112.904 43.1143 114.616 41.4021 114.616 39.2898C114.616 37.1776 112.904 35.4653 110.791 35.4653C108.679 35.4653 106.967 37.1776 106.967 39.2898C106.967 41.4021 108.679 43.1143 110.791 43.1143Z"
            fill="#EAEEF9"
          />
          <path
            d="M116.388 28.1895C117.831 28.1895 119 27.0202 119 25.5777C119 24.1352 117.831 22.9658 116.388 22.9658C114.946 22.9658 113.776 24.1352 113.776 25.5777C113.776 27.0202 114.946 28.1895 116.388 28.1895Z"
            fill="#EAEEF9"
          />
          <path
            d="M21.8016 43.0211C23.244 43.0211 24.4134 41.8517 24.4134 40.4092C24.4134 38.9667 23.244 37.7974 21.8016 37.7974C20.3591 37.7974 19.1897 38.9667 19.1897 40.4092C19.1897 41.8517 20.3591 43.0211 21.8016 43.0211Z"
            fill="#EAEEF9"
          />
          <path
            d="M5.85059 93.3928C8.5295 93.3928 10.7012 91.2212 10.7012 88.5422C10.7012 85.8633 8.5295 83.6917 5.85059 83.6917C3.17168 83.6917 1 85.8633 1 88.5422C1 91.2212 3.17168 93.3928 5.85059 93.3928Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_44_2)">
            <path
              d="M101.304 52.4529L102.05 112.526C102.05 114.578 100.371 116.164 98.319 116.164H39.7388C37.6866 116.164 36.0076 114.485 36.0076 112.526V35.2893C36.0076 33.2371 37.6866 31.6514 39.7388 31.6514H82.1815L101.304 52.4529Z"
              fill="url(#paint0_linear_44_2)"
            />
          </g>
          <path
            d="M66.5103 44.2441H45.8953C45.2423 44.2441 44.6826 43.6845 44.6826 43.0315C44.6826 42.3785 45.2423 41.8188 45.8953 41.8188H66.5103C67.1632 41.8188 67.7229 42.3785 67.7229 43.0315C67.7229 43.6845 67.1632 44.2441 66.5103 44.2441Z"
            fill="#D5DDEA"
          />
          <path
            d="M56.7158 50.9605H45.8953C45.2423 50.9605 44.6826 50.4008 44.6826 49.7478C44.6826 49.0948 45.2423 48.5352 45.8953 48.5352H56.6225C57.2755 48.5352 57.8352 49.0948 57.8352 49.7478C57.8352 50.4008 57.2755 50.9605 56.7158 50.9605Z"
            fill="#D5DDEA"
          />
          <path
            d="M82.1815 31.6514V48.2553C82.1815 50.5873 84.2337 52.4529 86.5657 52.4529H101.304"
            fill="#D5DDEA"
          />
          <path
            d="M53.9484 44.0251L58.3807 28.3913L76.5859 30.4883L70.2298 40.6767L75.1531 44.6101L64.5538 67.7938L65.1443 49.8451L53.9484 44.0251Z"
            fill="#EAEEF9"
          />
          <path d="M57.8825 26.1056L54.355 20.7957" stroke="#A2ABBE" strokeWidth="2" />
          <path d="M67.2899 23.4506L67.7043 10" stroke="#A2ABBE" strokeWidth="2" />
          <path d="M75.7242 26.2307L81.3552 14.0085" stroke="#A2ABBE" strokeWidth="2" />
          <path
            d="M56.5684 93.3734C56.1766 93.3734 55.8631 93.2166 55.5496 92.9815C55.001 92.433 55.001 91.4926 55.5496 90.944C59.1545 87.4174 63.9349 85.4583 69.0288 85.4583C74.1227 85.4583 78.9032 87.4174 82.5081 90.944C83.0567 91.4926 83.0567 92.433 82.5081 92.9815C81.9595 93.5301 81.0191 93.5301 80.4705 92.9815C77.4142 89.9252 73.3391 88.2795 69.0288 88.2795C64.7186 88.2795 60.6435 89.9252 57.5872 92.9815C57.2737 93.2166 56.9602 93.3734 56.5684 93.3734Z"
            fill="#989FB0"
          />
          <path
            d="M61.5056 72.3708L63.3864 70.49C63.9349 69.9414 63.9349 69.001 63.3864 68.4524C62.8378 67.9039 61.8974 67.9039 61.3488 68.4524L59.468 70.3333L57.5872 68.4524C57.0386 67.9039 56.0982 67.9039 55.5496 68.4524C55.001 69.001 55.001 69.9414 55.5496 70.49L57.4304 72.3708L55.5496 74.2517C55.001 74.8002 55.001 75.7406 55.5496 76.2892C55.8631 76.6027 56.1766 76.681 56.5684 76.681C56.9602 76.681 57.2737 76.5243 57.5872 76.2892L59.468 74.4084L61.3488 76.2892C61.6623 76.6027 61.9758 76.681 62.3676 76.681C62.7594 76.681 63.0729 76.5243 63.3864 76.2892C63.9349 75.7406 63.9349 74.8002 63.3864 74.2517L61.5056 72.3708Z"
            fill="#989FB0"
          />
          <path
            d="M80.5489 72.3708L82.4297 70.49C82.9783 69.9414 82.9783 69.001 82.4297 68.4524C81.8811 67.9039 80.9407 67.9039 80.3921 68.4524L78.5113 70.3333L76.6305 68.4524C76.0819 67.9039 75.1415 67.9039 74.5929 68.4524C74.0444 69.001 74.0444 69.9414 74.5929 70.49L76.4738 72.3708L74.5929 74.2517C74.0444 74.8002 74.0444 75.7406 74.5929 76.2892C74.9064 76.6027 75.2199 76.681 75.6117 76.681C76.0036 76.681 76.317 76.5243 76.6305 76.2892L78.5113 74.4084L80.3921 76.2892C80.7056 76.6027 81.0191 76.681 81.4109 76.681C81.8028 76.681 82.1162 76.5243 82.4297 76.2892C82.9783 75.7406 82.9783 74.8002 82.4297 74.2517L80.5489 72.3708Z"
            fill="#989FB0"
          />
          <defs>
            <filter
              id="filter0_d_44_2"
              x="14.0076"
              y="20.6514"
              width="110.043"
              height="128.512"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="11" />
              <feGaussianBlur stdDeviation="11" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_44_2" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_44_2"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_44_2"
              x1="69.0074"
              y1="29.6965"
              x2="69.0074"
              y2="117.075"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FDFEFF" />
              <stop offset="0.9964" stopColor="#ECF0F5" />
            </linearGradient>
          </defs>
        </Styled.Svg>
        <Styled.Text>{t('states.error')}</Styled.Text>
        {error && <Styled.ErrorInfo>{error}</Styled.ErrorInfo>}
      </Styled.Box>
    </Styled.Container>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

export type I18nContextValues = {
  translations: Record<string, any>;
};

export const I18nContext = createContext<I18nContextValues>({
  translations: {},
});

import React, { FC, ReactEventHandler } from 'react';
import * as Styled from './Chip.styled';

interface ChipProps extends Styled.BaseProps {
  onClick?: ReactEventHandler<HTMLDivElement>;
}

export const Chip: FC<ChipProps> = ({ children, color = 'default', ...props }) => {
  return (
    <Styled.Base color={color} {...props}>
      {children}
    </Styled.Base>
  );
};

import { I18nContextValues } from '@i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import { get, replace } from 'lodash';
import { setLocale } from 'yup';

interface Options {
  t: (key: string, options?: Record<string, any>) => string;
}

export function updateLocale(lng: string, { t }: Options) {
  dayjs.locale(lng);
  dayjs.updateLocale(lng, t('shared:dayjs', { returnObjects: true, lng }));

  setLocale({
    mixed: {
      required: t('shared:forms.validation.yup_defaults.mixed.required'),
    },
    string: {
      email: t('shared:forms.validation.yup_defaults.string.email'),
      url: t('shared:forms.validation.yup_defaults.string.url'),
    },
  });
}

type TranslateOptions = Record<string, string> & {
  returnObjects?: boolean;
};

export function translate(translations: I18nContextValues['translations'], namespace = 'shared') {
  return (key: string, options: TranslateOptions = {}) => {
    let currentNamespace = namespace;
    let currentKey = key;

    if (key.includes(':')) {
      [currentNamespace, currentKey] = key.split(':');
    }

    const initialValue = get(translations, `${currentNamespace}.${currentKey}`) ?? key;

    if (options.returnObjects) {
      return initialValue;
    }

    return Object.keys(options).reduce(
      (str, option) => replace(str, `{{${option}}}`, options[option]),
      initialValue,
    );
  };
}

import styled, { Color, css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { utils } }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: ${utils.rem(5)};
  `,
);

interface Props {
  isActive?: boolean;
  color: Color;
  invert?: boolean;
}

export const Status = styled.div<Props>(
  ({ theme: { utils }, isActive, color, invert }) => css`
    align-items: center;
    border: 1px solid transparent;
    border-radius: 100%;
    display: flex;
    font-size: ${utils.rem(10)};
    height: ${utils.rem(14)};
    margin-right: ${utils.rem(-5)};
    justify-content: center;
    width: ${utils.rem(14)};

    ${invert
      ? css`
          background-color: ${utils.color(color, 'main')};
          border-color: ${utils.background('default')};
          color: ${utils.background('default')};

          ${isActive &&
          css`
            background-color: ${utils.background('default')};
            color: ${utils.color(color, 'main')};
            border-color: ${utils.color(color, 'main')};
          `}
        `
      : css`
          background-color: ${utils.background('default')};
          border-color: ${utils.color(color, 'main')};
          color: ${utils.color(color, 'main')};

          ${isActive &&
          css`
            background-color: ${utils.color(color, 'main')};
            border-color: ${utils.color(color, 'textContrast')};
            color: ${utils.color(color, 'textContrast')};
          `}
        `}
  `,
);

import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image';

export const PATIENT_FRAGMENT = gql`
  fragment Patient on Patient {
    avatar {
      ...Image
    }
    fullName
    firstName
    lastName
    phoneNumber
    email
    id
    locale
  }
  ${IMAGE_FRAGMENT}
`;

import { gql } from '@apollo/client';
import { Patient } from '@types';
import { PATIENT_FRAGMENT } from '../fragments';

export interface CurrentContextResults {
  currentContext: {
    patient: Patient;
  };
}

export const CURRENT_CONTEXT = gql`
  query currentContext($accessToken: String!) {
    currentContext(accessToken: $accessToken) {
      patient {
        ...Patient
      }
    }
  }
  ${PATIENT_FRAGMENT}
`;

import { RestApi } from '../../../api';

const loadFile = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const data = window.URL.createObjectURL(blob);

    if (data) {
      return resolve(data);
    }
    return reject();
  });
};

export const FetchSecuredAttachment = (file: string): Promise<string> => {
  return RestApi.get(file, { responseType: 'blob' }).then(({ data }) => {
    return loadFile(data);
  });
};

import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from './attachment';
import { PARTICIPANT_FRAGMENT } from './participant';

export const MESSAGE_FRAGMENT = gql`
  fragment Message on Message {
    author {
      ...Participant
    }
    status
    createdAt
    content
    attachments {
      ...Attachment
    }
    id
  }
  ${ATTACHMENT_FRAGMENT}
  ${PARTICIPANT_FRAGMENT}
`;

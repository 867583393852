import { ApolloProvider } from '@apollo/client';
import { WithProviders } from '@shared';
import React from 'react';
import { GraphqlClient } from '../../api';
import { I18nProvider } from '../../i18n/provider';
import { ForgotPasswordForm } from './ForgotPasswordForm';

interface Props {
  i18n: Record<string, object>;
  locale: string;
}

export const ForgotPasswordPage = WithProviders<Props>((props) => {
  return (
    <I18nProvider i18n={props.i18n} locale={props.locale}>
      <ApolloProvider client={GraphqlClient('auth')}>
        <ForgotPasswordForm />
      </ApolloProvider>
    </I18nProvider>
  );
}, 'auth');

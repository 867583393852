import { gql } from '@apollo/client';
import { MESSAGE_FRAGMENT } from './message';
import { PATIENT_FRAGMENT } from './patient';

export const DISCUSSION_FRAGMENT = gql`
  fragment Discussion on Discussion {
    id
    patient {
      ...Patient
    }
    lastMessage {
      ...Message
    }
    status
    updatedAt
  }
  ${PATIENT_FRAGMENT}
  ${MESSAGE_FRAGMENT}
`;

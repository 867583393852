import { Input, InputProps } from '@ui';
import React, { FocusEventHandler, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export type TextFieldProps = Omit<InputProps, 'type' | 'input'> & {
  name: string;
  type?: 'textarea' | 'text' | 'number' | 'date' | 'time' | 'hidden' | 'password';
  onBlur?: FocusEventHandler;
};

const InputField = forwardRef<HTMLInputElement>((props, ref) => <input {...props} ref={ref} />);
const TextareaField = forwardRef<HTMLTextAreaElement>((props, ref) => (
  <textarea {...props} ref={ref} />
));

export const TextField = ({ type = 'text', name, defaultValue, ...props }: TextFieldProps) => {
  const { control } = useFormContext();
  const {
    field: { onBlur, value, ...field },
    fieldState,
  } = useController({
    control,
    name,
    defaultValue,
  });

  const handleBlur = (event: React.FocusEvent) => {
    event.persist();
    onBlur();
    props.onBlur?.(event);
  };

  if (type === 'hidden') {
    return <input type="hidden" {...field} value={value ?? ''} />;
  }

  return (
    <Input
      type={type}
      {...props}
      {...field}
      value={value ?? ''}
      onBlur={handleBlur}
      error={fieldState.error?.message}
      input={type === 'textarea' ? TextareaField : InputField}
    />
  );
};

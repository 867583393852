import React, { FC } from 'react';

interface Props {
  pharmacyAddress: pharmacyAddress;
}
interface pharmacyAddress {
  street: string;
  building_number: string;
  postal_code: string;
}

export const Maps: FC<Props> = ({ pharmacyAddress }) => {
  const API_KEY = `${process.env.MAPS_API_KEY}`;
  const ADRESSE = `${pharmacyAddress.street} ${pharmacyAddress.building_number} ${pharmacyAddress.postal_code} `;

  return (
    <iframe
      className="c-maps-frame"
      loading="lazy"
      allowFullScreen
      src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${ADRESSE}&zoom=18`}></iframe>
  );
};

import React from 'react';
import { AttachmentBox } from './Attachment';
import * as Styled from './Attachments.styled';

export interface Attachment {
  isLoading: boolean;
  file: File;
}

interface Props {
  attachments: Attachment[];
  deletable?: boolean;
  onDelete: (index: number) => void;
}

export const Attachments = ({ attachments, deletable, onDelete }: Props) => {
  if (attachments.length === 0) {
    return null;
  }

  return (
    <Styled.Container disabled={!deletable}>
      {attachments.map((attachment, index) => (
        <AttachmentBox
          key={index}
          disabled={!deletable}
          file={attachment.file}
          onDelete={() => onDelete(index)}
        />
      ))}
    </Styled.Container>
  );
};

import { ApolloProvider } from '@apollo/client';
import { Theme } from '@ui';
import React, { ComponentType, FC } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { GraphqlClient, GraphqlContext } from '../../../api';

export const WithProviders = <T,>(
  Component: ComponentType<T>,
  context: GraphqlContext = 'patient',
): FC<T> => {
  return (props) => {
    return (
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <ApolloProvider client={GraphqlClient(context ?? 'patient')}>
          <Component {...props} />
        </ApolloProvider>
      </ThemeProvider>
    );
  };
};

export const GlobalStyles = createGlobalStyle(({ theme: { utils } }) => ({
  body: {
    margin: 0,
    padding: 0,
    fontSize: '100%',
    fontFamily: utils.font('body'),
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
  },
  '*': {
    boxSizing: 'border-box',
  },
  '*[role=button]': {
    cursor: 'pointer',
  },
}));

import { gql } from '@apollo/client';

export const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on Attachment {
    id
    fileName
    originalUrl
    thumbUrl
    createdAt
  }
`;

import styled, { css } from 'styled-components';

interface DeletedProps {
  isDeleted?: boolean;
}

interface ContentProps {
  isMe?: boolean;
}

type Props = ContentProps & DeletedProps;

export const Message = styled.div<Props>(
  ({ theme: { utils, breakpoints }, isMe, isDeleted }) => css`
    align-items: center;
    display: flex;
    flex-flow: ${isMe ? 'row-reverse' : 'row'};
    justify-content: 'flex-start';
    width: 100%;

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      max-width: 85%;
    }

    &:hover ${Action} {
      opacity: 1;
    }

    ${isMe &&
    css`
      margin-left: auto;
    `}

    ${isDeleted &&
    css`
      opacity: 0.4;
    `}
  `,
);

export const Avatar = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    align-self: flex-end;
    background: ${utils.color('default', 'main')};
    border-radius: ${borderRadius};
    height: ${utils.rem(32)};
    width: ${utils.rem(32)};
  `,
);
export const Content = styled.div<ContentProps>(
  ({ theme: { utils, borderRadius }, isMe }) => css`
    background-color: ${utils.color('secondary', 'main')};
    border-radius: ${borderRadius};
    color: ${utils.color('secondary', 'textContrast')};
    flex: 1;
    max-width: ${utils.rem(500)};
    padding: ${utils.rem(8)};
    overflow: hidden;

    ${isMe
      ? css`
          background-color: ${utils.color('primary', 'main')};
          color: ${utils.color('primary', 'textContrast')};
          margin-right: ${utils.rem(12)};
        `
      : css`
          margin-left: ${utils.rem(12)};
        `}
  `,
);
export const Text = styled.div(
  ({ theme: { utils } }) => css`
    font-size: ${utils.rem(14)};
    line-height: 1.5;
    margin-bottom: ${utils.rem(4)};
    white-space: pre-line;
  `,
);
export const Meta = styled.div(
  ({ theme: { utils } }) => css`
    align-items: center;
    display: flex;
    font-size: ${utils.rem(12)};
    font-weight: 500;
    justify-content: flex-end;
    line-height: 1;
    text-align: right;
  `,
);
export const MetaText = styled.span(
  () => css`
    opacity: 0.7;
  `,
);
export const MetaDeleted = styled.span(
  ({ theme: { utils } }) => css`
    font-size: ${utils.rem(12)};
    margin-right: ${utils.rem(4)};
  `,
);
export const Status = styled.div(
  ({ theme: { utils } }) => css`
    margin-left: ${utils.rem(4)};
  `,
);
export const Action = styled.div(
  ({ theme: { utils } }) => css`
    color: ${utils.color('secondary', 'main', 0.7)};
    opacity: 0;
    transition: opacity 0.2;
  `,
);

export const Attachments = styled.div(
  ({ theme: { utils, borderRadius } }) => css`
    align-items: stretch;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: calc(${borderRadius} - ${utils.rem(1)});
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: ${utils.rem(-7, -7, 8)};
    min-height: ${utils.rem(200)};
    overflow: hidden;
    overflow-x: auto;
    text-align: center;
    z-index: 0;
  `,
);

export const BotMessage = styled.div(
  ({ theme: { utils } }) => css`
    position: relative;
    width: 100%;
    text-align: center;
    z-index: 0;

    &:before {
      background-color: ${utils.color('default', 'main')};
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
);

export const BotMessageText = styled.span(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('default')};
    color: ${utils.color('secondary', 'main', 0.7)};
    display: inline-block;
    font-size: ${utils.rem(14)};
    font-style: italic;
    font-weight: 500;
    padding: ${utils.rem(16, 16)};
    position: relative;
    z-index: 1;
  `,
);

import React, { createContext, FC, useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './Grid.styled';

type GridContainerProps = {
  gutter?: number;
  columns?: number;
};
type GridCellProps = {
  size?: Styled.GridSize;
};
type GridProps = {
  container?: boolean;
  gutter?: number;
  columns?: number;
  size?: Styled.GridSize;
};

export const Grid: FC<GridProps> = ({
  children,
  container,
  size,
  columns = 12,
  gutter = 16,
  ...props
}) => {
  if (container) {
    return (
      <GridContainer columns={columns} gutter={gutter} {...props}>
        {children}
      </GridContainer>
    );
  }

  return (
    <GridCell size={size} {...props}>
      {children}
    </GridCell>
  );
};

export const GridContext = createContext({
  gutter: 0,
  columns: 0,
});

export const GridContainer: FC<GridContainerProps> = ({ children, columns, gutter, ...props }) => {
  const { grid } = useTheme();

  const context = useMemo(
    () => ({
      columns: columns ?? grid.columns,
      gutter: gutter ?? grid.gutter,
    }),
    [grid, columns, gutter],
  );

  return (
    <GridContext.Provider value={context}>
      <Styled.GridContainer gutter={context.gutter} {...props}>
        {children}
      </Styled.GridContainer>
    </GridContext.Provider>
  );
};

const GridCell: FC<GridCellProps> = ({ children, size, ...props }) => {
  const { columns, gutter } = useContext(GridContext);

  return (
    <Styled.GridCell columns={columns} gutter={gutter} size={size} {...props}>
      {children}
    </Styled.GridCell>
  );
};

import styled, { css } from 'styled-components';

export const Item = styled.li(
  () => css`
    list-style: none;
    margin: 0;
  `,
);

export const Link = styled.a(
  ({ theme: { utils, breakpoints } }) => css`
    align-items: center;
    border-left: 2px solid transparent;
    color: currentColor;
    display: flex;
    justify-content: flex-start;
    min-height: ${utils.rem(50)};
    padding: ${utils.rem(0, 16, 0, 20)};
    text-decoration: none;
    transition: transform 0.2s ease-out;
    width: 100%;
    will-change: transform;

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      font-size: ${utils.rem(18)};
      margin-right: ${utils.rem(14)};
      padding-left: ${utils.rem(14)};
    }

    &:hover:not(.active) {
      cursor: pointer;
      transform: translateX(${utils.rem(-4)});
    }

    &.active {
      border-left-color: currentColor;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }
    }
  `,
);

export const Icon = styled.i(
  ({ theme: { utils, breakpoints } }) => css`
    font-size: ${utils.rem(20)};

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      font-size: ${utils.rem(18)};
      margin-right: ${utils.rem(14)};
    }
  `,
);

export const Label = styled.span(
  ({ theme: { utils, breakpoints } }) => css`
    display: none;
    flex: 1;
    font-weight: inherit;

    @media screen and (min-width: ${utils.rem(breakpoints.tablet)}) {
      display: block;
    }
  `,
);

export const End = styled.div`
  font-weight: 300;
`;

import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { utils } }) => css`
    background-color: ${utils.background('default')};
    padding: ${utils.rem(32)};
    height: 100%;
  `,
);

export const Header = styled.header(
  ({ theme: { utils } }) => css`
    border-bottom: 1px solid ${utils.color('default', 'main')};
    margin: ${utils.rem(0, -32)};
    padding: ${utils.rem(0, 32, 32)};
  `,
);

import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { ListItem } from './Item';
import * as Styled from './List.styled';
import { StyledListProps } from './List.styled';

export const List = ({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLUListElement> & StyledListProps>) => {
  return <Styled.List {...props}>{children}</Styled.List>;
};

List.Item = ListItem;

import React, { FC } from 'react';
import * as Styled from './Title.styled';

interface Props {
  tag?: keyof JSX.IntrinsicElements;
  level?: Styled.TitleLevel;
}

export const Title: FC<Props> = ({ children, tag = 'h1', level = '1' }) => {
  return (
    <Styled.Title as={tag} level={level}>
      {children}
    </Styled.Title>
  );
};

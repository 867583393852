import { useMutation } from '@apollo/client';
import { getValidationErrors, SIGNIN, SignInResults } from '@graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@i18n';
import {
  AuthLink,
  AuthLinks,
  getRememberMe,
  PasswordField,
  storeAccessToken,
  storeRefreshToken,
  SubmitButton,
  SwitchField,
  TextField,
} from '@shared';
import { Grid, Icon } from '@ui';
import { camelCase } from 'lodash';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SignInSchema } from './schema';
import * as Styled from './SignInForm.styled';

type SignInValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const SignInForm = () => {
  const { t } = useTranslation('signin');

  const form = useForm<SignInValues>({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: getRememberMe(),
    },
    resolver: yupResolver(SignInSchema({ t })),
  });

  const {
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting, isDirty },
  } = form;

  const [signIn, { error }] = useMutation<SignInResults>(SIGNIN, {
    onCompleted(data) {
      if (data?.signIn) {
        const { accessToken, refreshToken } = data.signIn;
        const rememberMe = getValues('rememberMe');
        const searchParams = new URLSearchParams(document.location.search);

        storeAccessToken(accessToken, rememberMe);
        storeRefreshToken(refreshToken, rememberMe);
        document.location.assign(searchParams.get('referer') ?? '/account');
      }
    },
  });

  const handleSave: SubmitHandler<SignInValues> = async ({ rememberMe, ...input }) => {
    return signIn({
      variables: {
        input: { ...input, context: 'patient' },
      },
    })
      .then(() => {
        // Nothing
      })
      .catch((errors) => {
        const validationErrors = getValidationErrors(errors);
        validationErrors.forEach(({ field, message }) => {
          setError(
            camelCase(field) as keyof SignInValues,
            {
              message: t(`shared:forms.validation.${message}`, {
                path: t(field),
              }),
              type: 'focus',
            },
            { shouldFocus: true },
          );
        });
      });
  };

  return (
    <Styled.Page>
      <Styled.Form>
        <Styled.Title>{t('title')}</Styled.Title>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleSave)}>
            {error && <Styled.ErrorMessage>{t('error')}</Styled.ErrorMessage>}

            <Grid container>
              <Grid>
                <TextField name="email" label={t('email')} prependIcon={<Icon icon="person" />} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid>
                <PasswordField
                  name="password"
                  label={t('password')}
                  prependIcon={<Icon icon="lock" />}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid>
                <SwitchField name="rememberMe" label={t('remember')} />
              </Grid>
            </Grid>

            <Styled.FormActions>
              <SubmitButton isDirty={isDirty} isSubmitting={isSubmitting} iconName="check">
                {t('submit')}
              </SubmitButton>
            </Styled.FormActions>
          </form>

          <AuthLinks>
            <AuthLink href="/forgot-password">{t('auth:links.forgot-password')}</AuthLink>
            <AuthLink href="/signup">{t('auth:links.sign-up')}</AuthLink>
          </AuthLinks>
        </FormProvider>
      </Styled.Form>
    </Styled.Page>
  );
};
